import React, { useState } from 'react';
import styled from 'styled-components';
import BoostPurchase from '../components/BoostPurchase';
import AiRequestPurchase from '../components/AiRequestPurchase';
import { colors } from '../styles/styles';

const StorePage = () => {
  const [activeTab, setActiveTab] = useState<'boosts' | 'aiRequests'>('boosts');

  return (
    <Container>
      <Title>Store</Title>
      <Tabs>
        <Tab active={activeTab === 'boosts'} onClick={() => setActiveTab('boosts')}>
          Boosts
        </Tab>
        <Tab active={activeTab === 'aiRequests'} onClick={() => setActiveTab('aiRequests')}>
          AI Requests
        </Tab>
      </Tabs>
      {activeTab === 'boosts' ? <BoostPurchase /> : <AiRequestPurchase />}
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 2vh;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Title = styled.h1`
  color: ${colors.primaryText};
  font-size: 3vh;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 2vh 0;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 1vh 2vh;
  cursor: pointer;
  background: ${({ active }) => (active ? colors.lightGreen : colors.bgSecondary)};
  color: ${({ active }) => (active ? colors.background : colors.primaryText)};
  border-radius: 1vh;
  font-size: 2vh;
  font-weight: bold;
  transition: background 0.3s;
`;

export default StorePage;


