import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { initDataStore } from '../store/initDataStore';
import Loader from '../components/helpers/Loader';
import { useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../api/api';
import { useUiStore } from '../store/userStore';
import { initializeTelegram, setupBackButton } from '../utils/telegram';

const SecureRoute: React.FC = () => {
    console.log('SecureRoute');
    const currentInitData = initDataStore.getState().initData;
    const [initDataAvailable, setInitDataAvailable] = useState(!!currentInitData);
    const navigate = useNavigate();

    useEffect(() => {
        const telegramWebApp = window.Telegram?.WebApp;
        const initData = telegramWebApp?.initData;

        if (initData) {
            console.log("Using Telegram initData");
            setInitDataAvailable(true);
            initDataStore.getState().setInitData(initData);

            initializeTelegram();
            setupBackButton(navigate);
        } else {
            console.log("Using default initData");
            const defaultInitData = "query_id=AAGdJCdOAgAAAJ0kJ04qzMwI&user=%7B%22id%22%3A5606155421%2C%22first_name%22%3A%22Anna%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22sobored19%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728123438&hash=5020a03cfd02dd7635c74449e271041b3f5091c8792a4755d83fc42800826b8f";
            setInitDataAvailable(true);
            initDataStore.getState().setInitData(defaultInitData);
        }
    }, [navigate]);

    // Запрашиваем профиль пользователя, если initData доступен
    const { data: user, isLoading } = useQuery({
        queryKey: ['user'],
        queryFn: getUserProfile,
        enabled: initDataAvailable,

    });
    useEffect(() => {
        if (user) {
            console.log('User data:', user);
            // Обновляем данные пользователя в сторах
            useUiStore.getState().updateUiUserData(user);
        }
    }, [user]);

    if (isLoading) {
        return <Loader />;
    }

    return <Outlet />;
};

export default SecureRoute;
