import { useEffect, useRef } from 'react';
import { syncInspirationClicks } from '../api/api';
import {useBoostStore} from "../store/boostStore"; // Путь к вашему API файлу

export const useInspirationClickSync = () => {
    const clicksRef = useRef(0); // Заменяем useState на useRef для хранения кликов
    const timerStartedRef = useRef(false); // Флаг для отслеживания запуска таймера
    const timerRef = useRef<NodeJS.Timeout | null>(null);



    // Функция для синхронизации кликов
    const setClicksToSync = async (totalClicks: number) => {
        console.log('setClicksToSync');
        try {
            console.log(`Синхронизация inspiration: ${totalClicks} кликов`);
            await syncInspirationClicks(totalClicks); // Вызов функции синхронизации
            clicksRef.current = 0; // Обнуляем ref после синхронизации
            timerStartedRef.current = false; // Разрешаем перезапуск таймера для будущих кликов
        } catch (error) {
            console.error('Ошибка синхронизации кликов:', error);
        }
    };

    const handleClick = () => {
        console.log('handleClick');
        clicksRef.current += 1; // Увеличиваем значение в ref без ререндера

        // Если таймер уже запущен, не перезапускаем его
        if (!timerStartedRef.current) {
            // Устанавливаем флаг, что таймер запущен
            timerStartedRef.current = true;

            // Устанавливаем таймер на 15 секунд
            timerRef.current = setTimeout(() => {
                setClicksToSync(clicksRef.current); // Синхронизируем клики после 15 секунд
            }, 15000);
        }
    };

    // Очистка таймера при размонтировании компонента
    useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);

    return handleClick;
};
