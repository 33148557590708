import React from 'react';
import ConfettiAnimation from './ConfettiAnimation';
import styled from 'styled-components';
import {colors, CtaButton} from '../styles/styles';
import {Reward} from "../store/rewardStore";
import inspirationBoostIcon from "../assets/images/inspiration-boost-icon.svg"
import rebirthBoostIcon from "../assets/images/rebirth-boost.svg"
import coinIcon from "../assets/images/coin-icon-green.svg"
import {useDisplayRewards} from "../hooks/useDisplayRewards";
import {motion} from "framer-motion";

interface PopupProps {
    onClose: () => void;
    header?: string; // Заголовок (опциональный)
    subTitle?: string; // Подзаголовок (опциональный)
    rewards?: Reward[]; // Значение награды (опциональное)
}

const Popup: React.FC<PopupProps> = ({
                                         onClose,
                                         header = "Congratulation!!",
                                         subTitle = "You've earned!",
                                         rewards = [],
                                     }) => {

    const {displayRewards} = useDisplayRewards();

    const rewardTitleMap: Record<Reward['type'], string> = {
        ai_question: 'AI Reward',
        leaderboard: 'Leaderboard Reward',
        mining: 'Mining Reward',
        task: 'Task Reward',
        launches_series: 'Launches Reward',
        invite_friends: 'Invite friends',
    };


    let map = {
        inspirations: 0,
        replenishments: 0,
        coins: 0

    }
    for (const item of rewards) {
        map.coins += item.coins;
        map.inspirations += item.inspirations;
        map.replenishments += item.replenishments;

    }

    const handleClosePopup = () => {
        onClose();
        displayRewards(rewards)
    }


    return (
        <PopupWrapper
            className="reward-popup"
            initial={{ opacity: 0 }}  // Начальное состояние
            animate={{ opacity: 1 }}  // Анимация: увеличение прозрачности
            transition={{
                duration: 0.2,  // Длительность анимации
                ease: "easeInOut"  // Эффект плавного перехода
            }}
        >
            <ConfettiAnimation />
            <PopupHeader>{header}</PopupHeader>
            <PopupSubTitle>{subTitle}</PopupSubTitle>
            {rewards.length > 0 && (
                <>
                    {rewards.map((reward, index) => (
                        <RewardItem key={index}>
                            {/* Выводим заголовок для каждого типа награды */}
                            <RewardTitle>
                                {rewardTitleMap[reward.type]} {/* Заголовок на основе типа */}
                            </RewardTitle>

                            {/* Отображаем соответствующую иконку и количество */}
                            {reward.inspirations > 0 && (
                                <RewardDescription>
                                    <Icon src={inspirationBoostIcon} />
                                    Inspiration boost: {reward.inspirations}
                                </RewardDescription>
                            )}

                            {reward.replenishments > 0 && (
                                <RewardDescription>
                                    <Icon src={rebirthBoostIcon} />
                                    Replenishments: {reward.replenishments}
                                </RewardDescription>
                            )}

                            {reward.coins > 0 && (
                                <RewardDescription>
                                    <Icon src={coinIcon} />
                                    Coins: {reward.coins}
                                </RewardDescription>
                            )}
                        </RewardItem>
                    ))}
                </>
            )}
            <ClosePopupButton onClick={handleClosePopup}>OK</ClosePopupButton>
        </PopupWrapper>
    );
};

const PopupWrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 20px);
    border: 1px solid #69FF93;
    align-items: center;
    position: fixed;
    bottom: 8.2vh;
    left: 50%;
    height: 86%;
    transform: translate(-50%, 0);
    background: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
    background: radial-gradient(163.59% 90.02% at 50% 0%, rgba(68, 94, 76, 0.8) 0%, rgba(9, 9, 17, 0.8) 77.42%);
    box-shadow: inset 0px 0px 130px #090911;
    backdrop-filter: blur(4px);

`;

const RewardDescription = styled.div`
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 1.5vh;
    display: flex;
    align-items: center;
`;

const PopupHeader = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
    color: #FFFFFF;
    z-index: 10001;
    margin-bottom: 15px;
`;

const PopupSubTitle = styled.h2`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #A7A7A7;
    margin-bottom: 50px;
`;

const RewardTitle = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 2vh;
`;

const Icon = styled.img`
    margin-right: 1vh;
`;
const RewardItem = styled.div`
    display: flex;
    flex-direction: column;
`;
const ClosePopupButton = styled(CtaButton)`
    width: 100px;
    height: 32px;
`;

export default Popup;
