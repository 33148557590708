import { create } from 'zustand';
import { useUiStore } from "./userStore";
import { activateReplenishment, startMining, syncInspirationClicks } from "../api/api";
import inspirationAnimatedIcon from "../assets/animations/Inspiration-boost-icon.gif";
import rebirthAnimatedIcon from "../assets/animations/rebirth-boost.gif";

interface Boost {
    name: string;
    description: string;
    effect: string;
    activation: string;
    duration?: string;
    isActive: boolean;
    isActivated?: boolean;
    availableCount: number; // Количество доступных бустов
    image: string;
}

interface BoostStore {
    boosts: Record<string, Boost>;
    activateBoost: (boostKey: string) => void;
    deactivateBoost: (boostKey: string) => void;
    activateBoostVisualEffect: (boostKey: string) => void;
    deactivateBoostVisualEffect: (boostKey: string) => void;
    reduceBoostCount: (boostKey: string) => void;
    increaseBoostCount: (boostKey: string, amount: number) => void; // Новый метод для увеличения бустов
    error: string | null;
    clearError: () => void;
    setError: (error: string | null) => void;
    addBoosts: (boostType: string, amount: number) => void;
}

export const useBoostStore = create<BoostStore>((set, get) => ({
    boosts: {
        inspiration: {
            name: 'Inspiration Boost',
            description: 'This boost fills you with energy and strength for new achievements.',
            effect: 'Instant energy restoration to maximum.',
            activation: 'From the first tap on coin after activation.',
            duration: '15 seconds',
            isActivated: false,
            isActive: false,
            availableCount: 1, // Начальное количество доступных бустов
            image: inspirationAnimatedIcon
        },
        rebirth: {
            name: 'Rebirth Boost',
            description: 'Instantly restores your energy to the maximum level.',
            effect: 'Instant energy restoration to maximum.',
            activation: 'Immediately.',
            isActive: false,
            availableCount: 1, // Начальное количество доступных бустов
            image: rebirthAnimatedIcon
        },
    },

    error: null,
    clearError: () => set({ error: null }),
    setError: (error) => set({ error }),

    activateBoost: async (boostKey) => {
        const { restoreEnergy, setIsInspirationBoostActive, activateEnergyProtection, deactivateEnergyProtection } = useUiStore.getState();
        const boosts = get().boosts;
        const boost = boosts[boostKey];

        // Проверяем, есть ли доступные бусты
        if (boost.availableCount <= 0) {
            get().setError(`${boost.name} закончились`);
            return;
        }

        // Активируем буст
        if (boostKey === 'rebirth') {
            const activateReplenishmentResponse = await activateReplenishment();

            if (activateReplenishmentResponse?.message.status === 'Error') {
                return; // Если ошибка, ничего не делаем
            }

            restoreEnergy(); // Восстанавливаем энергию
            setTimeout(() => {
                get().deactivateBoost(boostKey); // Деактивируем буст через 3 секунды
            }, 3000);
        }

        if (boostKey === 'inspiration') {
            setIsInspirationBoostActive(true);
            activateEnergyProtection(); // Активируем защиту энергии

            setTimeout(() => {
                get().deactivateBoost(boostKey);
                setIsInspirationBoostActive(false);
                deactivateEnergyProtection();
                get().deactivateBoostVisualEffect(boostKey);
            }, 15000);
        }

        set((state) => ({
            boosts: {
                ...state.boosts,
                [boostKey]: { ...state.boosts[boostKey], isActive: true },
            },
        }));

        // Уменьшаем количество доступных бустов после активации
        get().reduceBoostCount(boostKey);
    },

    deactivateBoost: (boostKey) => {
        set((state) => ({
            boosts: {
                ...state.boosts,
                [boostKey]: { ...state.boosts[boostKey], isActive: false },
            },
        }));
    },

    reduceBoostCount: (boostKey) => {
        set((state) => ({
            boosts: {
                ...state.boosts,
                [boostKey]: {
                    ...state.boosts[boostKey],
                    availableCount: state.boosts[boostKey].availableCount - 1,
                },
            },
        }));
    },

    // Новый метод для увеличения количества бустов на определенное значение
    increaseBoostCount: (boostKey, amount) => {
        set((state) => ({
            boosts: {
                ...state.boosts,
                [boostKey]: {
                    ...state.boosts[boostKey],
                    availableCount: state.boosts[boostKey].availableCount + amount,
                },
            },
        }));
    },

    activateBoostVisualEffect: (boostKey) => {
        set((state) => ({
            boosts: {
                ...state.boosts,
                [boostKey]: { ...state.boosts[boostKey], isActivated: true },
            },
        }));
    },
    deactivateBoostVisualEffect: (boostKey) => {
        set((state) => ({
            boosts: {
                ...state.boosts,
                [boostKey]: { ...state.boosts[boostKey], isActivated: false },
            },
        }));
    },

    addBoosts: (boostType: string, amount: number) => 
        set((state) => ({
            boosts: {
                ...state.boosts,
                [boostType]: {
                    ...state.boosts[boostType],
                    availableCount: state.boosts[boostType].availableCount + amount
                }
            }
        })),
}));
