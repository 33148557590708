import React, {useEffect} from 'react';
import styled from 'styled-components';
import MainButtonIcon from "../../assets/images/mane-button-icon.png";
import animation from "../../assets/animations/Wave_Coin_Effect_green_3.gif";

const Loader: React.FC = () => {

    return (
        <LoaderContainer>
            <ButtonContentWrapper>
                <CoinIcon src={MainButtonIcon}/>
                <ButtonBackgroundImage
                    src={animation}
                    alt="Animation"
                />
            </ButtonContentWrapper>
        </LoaderContainer>
    );
};

const ButtonBackgroundImage = styled.img`
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #050F11;
    // Добавляем внутреннюю тень
`;

const CoinIcon = styled.img`
    transition: transform 0.3s ease-in-out;
    position: absolute;
    z-index: 3;
    /* Запрещаем выделение и перетаскивание */
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
    draggable: false;
    animation: upDown 1s ease-in-out infinite;

    @keyframes upDown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px); // Значение можно изменять по вашему усмотрению
        }
        100% {
            transform: translateY(0);
        }
    }

`;
const ButtonContentWrapper = styled.div`
    position: relative;
    width: 200px; // Задайте нужный размер контейнера
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    overflow: hidden; // Прячем всё, что выходит за границы контейнера
    border-radius: 50%; // Округляем контейнер, чтобы он был круглый
    border: none;


    // Псевдоэлемент для внутренней тени

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0 0 15px 15px #02080a;
        pointer-events: none; // Не мешает взаимодействию с изображением
        z-index: 1; // Псевдоэлемент должен быть над изображением
        border: none;

    }
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;


export default Loader;
