import React, { useState } from 'react';
import styled from 'styled-components';
import { useAiRequestStore } from '../store/aiRequestStore';
import { CtaButton, colors } from '../styles/styles';
import { FaStar } from "react-icons/fa";

const AiRequestPurchase = () => {
  const { requests, maxRequests, addRequests, upgradeToMax } = useAiRequestStore();
  const [amount, setAmount] = useState(0);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isLimitBlinking, setIsLimitBlinking] = useState(false);

  const REQUEST_PRICE = 1; // Цена за один запрос

  const calculatePrice = (amount: number) => amount * REQUEST_PRICE;

  const handleSliderChange = (value: string) => {
    const newAmount = parseInt(value);
    setAmount(newAmount);
    
    if (requests + newAmount > maxRequests) {
      setIsWarningVisible(true);
      setIsLimitBlinking(true);
      setTimeout(() => setIsLimitBlinking(false), 1000);
    } else {
      setIsWarningVisible(false);
      setIsLimitBlinking(false);
    }
  };

  const handlePurchase = async (amount: number, price: number) => {
    try {
      const tg = window.Telegram.WebApp;
      const title = `${amount} AI Requests`;
      const description = `Purchase ${amount} AI requests`;
      const invoiceParams = {
        title,
        description,
        payload: JSON.stringify({ type: 'aiRequest', amount }),
        provider_token: process.env.REACT_APP_PROVIDER_TOKEN || '',
        currency: 'USD',
        prices: [{ label: title, amount: price * 100 }],
        max_tip_amount: 0,
        suggested_tip_amounts: [],
        need_name: false,
        need_phone_number: false,
        need_email: false,
        need_shipping_address: false,
        is_flexible: false
      };

      const result = await tg.openInvoice(invoiceParams);

      if (result) {
        addRequests(amount);
      }
    } catch (error) {
      console.error('Purchase failed:', error);
    }
  };

  const PACK_SIZE = 1000;
  const PACK_DISCOUNT = 30; // 30% скидка
  const packPrice = calculatePrice(PACK_SIZE);
  const discountedPrice = packPrice * (1 - PACK_DISCOUNT / 100);

  return (
    <>
      <RequestCard>
        <RequestInfo>
          <RequestCount isBlinking={isLimitBlinking}>
            Requests: {requests}/{maxRequests}
          </RequestCount>
          <UpgradeButton onClick={upgradeToMax}>Upgrade to Max</UpgradeButton>
        </RequestInfo>
        {isWarningVisible && (
          <WarningMessage>
            Request limit will be exceeded. Excess requests won't be added.
          </WarningMessage>
        )}
        <SliderContainer>
          <Slider
            type="range"
            min="0"
            max="1000"
            value={amount}
            onChange={(e) => handleSliderChange(e.target.value)}
          />
          <SliderMarks>
            {[0, 250, 500, 750, 1000].map(mark => <Mark key={mark}>{mark}</Mark>)}
          </SliderMarks>
        </SliderContainer>
        <BuyButton
          disabled={amount === 0}
          onClick={() => handlePurchase(amount, calculatePrice(amount))}
        >
          {amount === 0 ? 'Select amount' : `Buy for ${calculatePrice(amount)}`}
        </BuyButton>
      </RequestCard>

      <RequestCard>
        <MysteryPackSection>
          <PackHeader>
            <PackTitle>Seeker's Bundle</PackTitle>
            <DiscountBadge>{PACK_DISCOUNT}% OFF</DiscountBadge>
          </PackHeader>
          
          <PackDescription>
            Unlock the mysteries of the universe with {PACK_SIZE} AI requests
          </PackDescription>
          
          <PackPricing>
            <OldPrice>{packPrice} <FaStar /></OldPrice>
            <NewPrice>{discountedPrice} <FaStar style={{ color: 'gold' }} /></NewPrice>
          </PackPricing>

          <BuyPackButton 
            onClick={() => handlePurchase(PACK_SIZE, discountedPrice)}
          >
            Reveal the Unknown
          </BuyPackButton>
        </MysteryPackSection>
      </RequestCard>
    </>
  );
};

// Стили аналогичны BoostPurchase
const RequestCard = styled.div`
  background: ${colors.bgSecondary};
  border-radius: 1.5vh;
  padding: 2vh;
  margin-top: 3vh;
`;

const RequestInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
`;

const RequestCount = styled.div<{ isBlinking: boolean }>`
  color: ${colors.primaryText};
  font-size: 2vh;
  ${props => props.isBlinking && `
    animation: blinkWarning 0.5s ease-in-out;
  `}

  @keyframes blinkWarning {
    0%, 100% { color: ${colors.primaryText}; }
    50% { color: #ff4444; }
  }
`;

const UpgradeButton = styled(CtaButton)`
  font-size: 1.6vh;
  padding: 0.5vh 1vh;
`;

const SliderContainer = styled.div`
  padding: 0 1vh;
`;

const SliderMarks = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.5vh;
  margin-top: 0.5vh;
`;

const Mark = styled.span`
  color: ${colors.secondaryText};
  font-size: 1.4vh;
  position: relative;
  width: 6%;
  text-align: center;
  
  &::before {
    content: '';
    position: absolute;
    top: -1vh;
    left: 50%;
    transform: translateX(-50%);
    width: 0.2vh;
    height: 0.5vh;
    background: ${colors.secondaryText};
  }
`;

const Slider = styled.input`
  width: 100%;
  height: 0.5vh;
  background: ${colors.lightGreen}40;
  border-radius: 0.25vh;
  -webkit-appearance: none;
  margin: 1vh 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 2.5vh;
    height: 2.5vh;
    background: ${colors.lightGreen};
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 1vh ${colors.lightGreen}40;
    transition: all 0.2s ease;
    position: relative;
    top: -1vh;
    z-index: 1;
  }

  &::-webkit-slider-thumb:hover {
    transform: scale(1.1);
    box-shadow: 0 0 1.5vh ${colors.lightGreen}60;
  }

  &::-webkit-slider-runnable-track {
    height: 0.5vh;
    border-radius: 0.25vh;
  }
`;

const BuyButton = styled(CtaButton)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 1vh;
`;

const WarningMessage = styled.div`
  color: #ff4444;
  font-size: 1.4vh;
  margin: -1vh 0 1vh;
  opacity: 0.9;
`;

const MysteryPackSection = styled.div`
  padding: 1vh 0;
`;

const PackHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
`;

const PackTitle = styled.h3`
  color: ${colors.primaryText};
  font-size: 2.2vh;
  margin: 0;
`;

const DiscountBadge = styled.span`
  background: ${colors.lightGreen};
  color: ${colors.background};
  padding: 0.5vh 1vh;
  border-radius: 1vh;
  font-size: 1.6vh;
  font-weight: bold;
`;

const PackDescription = styled.p`
  color: ${colors.secondaryText};
  font-size: 1.6vh;
  margin: 0 0 2vh;
  font-style: italic;
`;

const PackPricing = styled.div`
  display: flex;
  align-items: center;
  gap: 1vh;
  margin-bottom: 2vh;
`;

const OldPrice = styled.span`
  color: ${colors.secondaryText};
  text-decoration: line-through;
  font-size: 1.6vh;
  display: flex;
  align-items: center;
  gap: 0.5vh;
`;

const NewPrice = styled.span`
  color: ${colors.lightGreen};
  font-size: 2vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5vh;
`;

const BuyPackButton = styled(BuyButton)`
  background: ${colors.lightGreen};
  color: ${colors.background};
  font-weight: bold;
  
  &:hover {
    background: ${colors.lightGreen}ee;
  }
`;

export default AiRequestPurchase; 