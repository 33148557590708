import { create } from 'zustand';
import { claimReward, getRewardsList } from "../api/api";

// Определяем интерфейс награды
export interface Reward {
    id: number;
    type: 'ai_question' | 'leaderboard' | 'mining' | 'task' | 'launches_series' | 'invite_friends'
    coins: number;
    inspirations: number;
    replenishments: number;
    // title: 'Leaderboard reward' | 'Launches reward' | 'Ai reward' | 'Mining reward' | 'Task reward' | 'Invite friends'
}

// Определяем интерфейс состояния Store
interface RewardStoreState {
    rewards: Reward[]; // Все награды
    rewardsToClaim: Reward[]; // Награды, которые нужно заклеймить
    setRewards: (rewards: Reward[]) => void; // Устанавливаем список всех наград
    setRewardsToClaim: (rewards: Reward[]) => void; // Устанавливаем список наград для клейминга
    claimRewards: () => Promise<void>; // Клейминг наград
}

// Создаем Zustand store
export const useRewardStore = create<RewardStoreState>((set, get) => ({
    rewards: [], // Изначальное состояние для списка наград
    rewardsToClaim: [], // Изначальное состояние для списка наград к клеймингу

    // Устанавливаем список всех наград
    setRewards: (rewards: Reward[]) => set({ rewards }),

    // Устанавливаем список наград, которые нужно заклеймить
    setRewardsToClaim: (rewardsToClaim: Reward[]) => set({ rewardsToClaim }),

    // Функция для клейминга наград
    claimRewards: async () => {
        const { rewardsToClaim, rewards } = get(); // Получаем награды для клейминга и все награды

        try {
            // Проходим по всем наградам для клейминга
            for (const reward of rewardsToClaim) {
                await claimReward(reward.id); // Клеймим награду через API
                console.log(`Награда с ID ${reward.id} успешно заклеймлена`);
            }

            // Удаляем заклеймленные награды из общего списка наград
            const updatedRewards = rewards.filter(
                reward => !rewardsToClaim.some(toClaim => toClaim.id === reward.id)
            );

            // Очищаем список наград к клеймингу и обновляем список всех наград
            set({ rewards: updatedRewards, rewardsToClaim: [] });
        } catch (error) {
            console.error("Ошибка при клейминге наград:", error);
        }
    }
}));
