import {ranks, useUiStore} from "../store/userStore";
import {useMemo} from "react";

export const useTapPower =  () => {


    const isInspirationBoostActive = useUiStore(state => state.isInspirationBoostActive)
    const currentRank = useUiStore(state => state.currentRank)
    const currentLevel = useUiStore(state => state.currentLevel)
    const pressForce = ranks[currentRank].levels[currentLevel - 1].impactForce

    // Мемоизируем значение на основе зависимостей
    const tapPower = useMemo(() => {
        const basePressForce = pressForce;
        return isInspirationBoostActive ? basePressForce * 3 : basePressForce;
    }, [isInspirationBoostActive, pressForce]);
    return tapPower;
};
