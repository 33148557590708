import { useCallback } from "react";
import { Reward } from "../store/rewardStore";
import { useUiStore } from "../store/userStore";
import { useBoostStore } from "../store/boostStore";

export const useDisplayRewards = () => {
    const increaseBalance = useUiStore((state) => state.increaseBalance);
    const increaseBoostCount = useBoostStore((state) => state.increaseBoostCount);

    const displayRewards = useCallback((rewards: Reward[]) => {
        let map = {
            inspirations: 0,
            replenishments: 0,
            coins: 0
        };

        for (const item of rewards) {

            // Обновляем количество вдохновений и пополнений
            map.coins += item.coins;
            map.inspirations += item.inspirations;
            map.replenishments += item.replenishments;
        }

        // Увеличиваем баланс монет
        increaseBalance(map.coins);

        // Увеличиваем количество бустов на основе наград
        if (map.inspirations > 0) {
            increaseBoostCount("inspiration", map.inspirations); // Увеличиваем количество вдохновений
        }

        if (map.replenishments > 0) {
            increaseBoostCount("rebirth", map.replenishments); // Увеличиваем количество пополнений (например, rebirth)
        }
    }, [increaseBalance, increaseBoostCount]);

    return { displayRewards };
};
