import React, {useEffect} from 'react';
import styled from 'styled-components';
import {colors} from "../styles/styles";

import RankIcon from '../assets/images/footer/rank-icon.svg';
import LeaderIcon from '../assets/images/footer/leader-icon.svg';
import FrensIcon from '../assets/images/footer/frens-icon.svg';
import TasksIcon from '../assets/images/footer/tasks-icon.svg';
import HomeIcon from '../assets/images/footer/home-icon.svg';
import {Link, NavLink} from "react-router-dom";


const FooterContainer = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.497vh 1.491vh;
    background-color: ${colors.backgroundBlack};
    margin-top: 1vh;


`;

const IconRow = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
`;

const IconLabel = styled.span`
    font-size: 8px;
    color: ${colors.secondaryText};
    width: 100%; /* Чтобы текст был центрирован в пределах IconWrapper */
    text-transform: uppercase;
    z-index: 2;

    @media (max-width: 375px) {
        font-size: 7px;
    }

    @media (min-width: 805px) {
        font-size: 9px;
    }
`;

const StyledLink = styled(NavLink)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 6.708vh; /* Фиксированная ширина */
    height: 6.708vh; /* Фиксированная высота */
    text-align: center;
    text-decoration: none; /* Убираем подчеркивание */
    position: relative;


    &.active {
        color: #69FF93;
        border: 1px solid #69FF93;
        border-radius: 8px;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: -1px;
            z-index: 2;
            border-radius: 8px;
            background: linear-gradient(180deg, #0000, #00000080 30%, #000);
        }

        img {
            filter: brightness(0) saturate(100%) invert(73%) sepia(40%) saturate(2377%) hue-rotate(83deg) brightness(105%) contrast(94%);
        }

        span {
            color: #69FF93;
        }


    }
`;

const Icon = styled.img`
    margin-bottom: 0.5vh;
    z-index: 2;
`;


const Footer: React.FC = () => {

    return (
        <FooterContainer>
            <IconRow>
                <StyledLink to={"/"}>
                    <Icon src={HomeIcon} alt="Home"/>
                    <IconLabel>Home</IconLabel>
                </StyledLink>
                <StyledLink to={"/tasks"}>
                    <Icon src={TasksIcon} alt="Home"/>
                    <IconLabel>Tasks</IconLabel>
                </StyledLink>
                <StyledLink to={"/ranks"}>
                    <Icon src={RankIcon} alt="Home"/>
                    <IconLabel>Rank</IconLabel>
                </StyledLink>
                <StyledLink to={"/leaderboard"}>
                    <Icon src={LeaderIcon} alt="Home"/>
                    <IconLabel>Leader</IconLabel>
                </StyledLink>
                <StyledLink to={"/frens"}>
                    <Icon src={FrensIcon} alt="Home"/>
                    <IconLabel>Frens</IconLabel>
                </StyledLink>
            </IconRow>
        </FooterContainer>
    );
};


export default Footer;
