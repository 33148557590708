import { NavigateFunction } from "react-router-dom";

// Создайте отдельный утилитный файл для работы с Telegram WebApp
export const telegram = window.Telegram?.WebApp;

export const initializeTelegram = () => {
    if (telegram) {
        telegram.expand();
        telegram.disableVerticalSwipes();
        return true;
    }
    return false;
};

export const setupBackButton = (navigate: NavigateFunction) => {
    if (telegram?.BackButton) {
        telegram.BackButton.show();
        telegram.BackButton.onClick(() => navigate(-1));
    }
}; 