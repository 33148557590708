import React from 'react';
import tasksBg from "../assets/images/tasks-bg.png";
import TaskList from "../components/TaskList";
import Layout from "./Layout";

const TasksPage = () => {
    return (
        <Layout background={tasksBg}>
            <TaskList/>
        </Layout>
    );
}


export default TasksPage;