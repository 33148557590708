import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../styles/styles";

// Тип для click
interface Click {
    id: number;
    x: number;
    y: number;
}

// Тип для пропсов компонента FloatingPointsComponent
interface FloatingPointsComponentProps {
    click: Click;
    pointsToAdd: number;
    handleAnimationEnd: (id: number) => void;
}

// Сам компонент с мемоизацией
export const FloatingPointsComponent: React.FC<FloatingPointsComponentProps> = React.memo(({ click, pointsToAdd, handleAnimationEnd }) => {
    return (
        <FloatingPoints
            style={{
                top: `${click.y - 10}px`,
                left: `${click.x - 10}px`,
            }}
            onAnimationEnd={() => handleAnimationEnd(click.id)}
        >
            +{pointsToAdd}
        </FloatingPoints>
    );
});

// Анимация всплытия очков
const float = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
`;

// Стили для FloatingPoints
const FloatingPoints = styled.div`
  font-size: 27px;
  font-weight: bold;
  color: ${colors.activeText};
  opacity: 0;
  position: absolute;
  animation: ${float} 1s ease-out;
  z-index: 6;
`;
