import {create} from 'zustand';
import {User} from "./initDataStore";

interface FrensStore {
    frensList: User[];
    addFren: (fren: User) => void;
    removeFren: (index: number) => void;
    setFrensList: (frens: User[]) => void;
}

export const useFrensStore = create<FrensStore>((set) => ({
    frensList: [],
    setFrensList: (frens) => set({frensList: frens}),
    addFren: (fren) => set((state) => ({
        frensList: [...state.frensList, fren],
    })),
    removeFren: (index) => set((state) => ({
        frensList: state.frensList.filter((_, i) => i !== index),
    })),
}));
