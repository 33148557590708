import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MainButtonIcon from "../assets/images/mane-button-icon.png";
import animation from "../assets/animations/Wave_Coin_Effect_green_3.gif";
import InspirationBoostAnimation from "../assets/animations/Inspiration-boost-effect.gif";
import styled, {keyframes} from "styled-components";
import {useUiStore} from "../store/userStore";
import {useClickSync} from "../hooks/useClickSync";
import {useTapPower} from "../hooks/useTapPower";
import {useBoostStore} from "../store/boostStore";
import {colors} from "../styles/styles";
import {FloatingPointsComponent} from "./FloatingPointsComponent";
import {useInspirationClickSync} from "../hooks/useInspirationClickSync";

interface Click {
    id: number;
    x: number;
    y: number;
}

const InteractiveArea = () => {

    // useEffect(() => {
    //     console.log('InteractiveArea rendered')
    // });

    // Глобальные состояния
    const isInspirationBoostActivated = useBoostStore(state => state.boosts['inspiration'].isActivated);
    const energy = useUiStore((state) => state.energy);
    const changeEnergy = useCallback(useUiStore((state) => state.changeEnergy), []);
    const handleSyncClicks = useClickSync();
    const handleInspirationClickSync =  useInspirationClickSync();
    const tapPower = useTapPower();
    const activateBoost = useBoostStore(state => state.activateBoost);
    const increaseBalance = useCallback(useUiStore((state) => state.increaseBalance), []);

    // Локальные состояния
    const [isBoostActive, setIsBoostActive] = useState(isInspirationBoostActivated);
    const [isClicked, setIsClicked] = useState(false);
    const [clicks, setClicks] = useState<Click[]>([]);
    const [points, setPoints] = useState<number>(29857775);
    const [scale, setScale] = useState<number>(1);
    const [coinScale, setCoinScale] = useState<number>(1);

    const pointsToAdd = useMemo(() => tapPower, [tapPower]);
    const energyToReduce = useMemo(() => tapPower, [tapPower]);


    useEffect(() => {
        if (isInspirationBoostActivated !== isBoostActive) {
            setIsBoostActive(isInspirationBoostActivated);
            setIsClicked(false);
        }
    }, [isInspirationBoostActivated, isBoostActive]);


    // анимации
    const handleScale = useCallback(() => {
        setScale(1.2);
        setCoinScale(0.8);

        setTimeout(() => {
            setScale(1);
            setCoinScale(1);
        }, 300);
    }, []);

    const handleAnimationEnd = useCallback((id: number) => {
        setClicks((prevClicks) => prevClicks.filter(click => click.id !== id));
    }, []);

    const handleInteraction = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
            if (energy - tapPower < 0) return;

            let x: number, y: number;

            if ('touches' in e) {
                const rect = e.currentTarget.getBoundingClientRect();
                const touch = e.touches[0];
                x = touch.clientX - rect.left;
                y = touch.clientY - rect.top;
            } else {
                const rect = e.currentTarget.getBoundingClientRect();
                x = e.clientX - rect.left;
                y = e.clientY - rect.top;
            }

            setClicks((prevClicks) => [...prevClicks, {id: Date.now(), x, y}]);

            if (!isClicked && isInspirationBoostActivated) {
                activateBoost('inspiration');
                setIsClicked(true);

            }

            setPoints((prevPoints) => prevPoints + tapPower);
            changeEnergy(energy - energyToReduce < 0 ? 0 : -energyToReduce);
            increaseBalance(tapPower);
            handleScale();

            handleSyncClicks();
            if (isInspirationBoostActivated) {
                handleInspirationClickSync(); // Синхронизация кликов только если буст активен
            }
        },
        [energy, tapPower, isClicked, isInspirationBoostActivated, activateBoost, handleSyncClicks, changeEnergy, increaseBalance]
    );


    return (
        <InteractiveAreaContainer
            onMouseDown={(e) => {
                if (!('ontouchstart' in window)) {
                    handleInteraction(e);
                }
            }}
            onTouchStart={(e) => {
                if ('ontouchstart' in window) {
                    handleInteraction(e);
                }
            }}
        >
            <ButtonContentWrapper>
                <CoinIcon isBoostActive={isBoostActive} scale={coinScale} src={MainButtonIcon}/>
                <ButtonBackgroundImage
                    isBoostActive={isBoostActive}
                    src={animation}
                    alt="Animation"
                    scale={scale}
                />
                {isBoostActive && <InspirationBoostEffect src={InspirationBoostAnimation} alt="Animation"/>}
            </ButtonContentWrapper>
            {clicks.map((click) => (
                <FloatingPointsComponent
                    key={click.id}
                    click={click}
                    pointsToAdd={pointsToAdd}
                    handleAnimationEnd={handleAnimationEnd}
                />
            ))}
        </InteractiveAreaContainer>
    );
};

const InteractiveAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    position: absolute;
    top: 15%;
    cursor: pointer;
`;

const ButtonContentWrapper = styled.div`
    position: relative;
    //width: 200px; 
    width: 24.845vh;
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 200px;
    overflow: hidden; // Прячем всё, что выходит за границы контейнера
    border-radius: 50%; // Округляем контейнер, чтобы он был круглый
    border: none;


    // Псевдоэлемент для внутренней тени

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0 0 15px 15px #02080a;
        pointer-events: none; // Не мешает взаимодействию с изображением
        z-index: 1; // Псевдоэлемент должен быть над изображением
        border: none;

    }
`;

interface ImageProps {
    scale?: number;
    isBoostActive?: boolean;
    mixedMod?: string;
}

const CoinIcon = styled.img<ImageProps>`
    transform: ${({scale}) => `scale(${scale})`};
    transition: transform 0.3s ease-in-out;
    position: absolute;
    z-index: 3;
    /* Запрещаем выделение и перетаскивание */
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
    draggable: false;
    width: 14.783vh;
    

`;

const ButtonBackgroundImage = styled.img<ImageProps>`
    border-radius: 50%;
    transform: ${({scale}) => `scale(${scale})`};
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #050F11;
    mix-blend-mode: ${({mixedMod}) => mixedMod};
    user-select: none;

    // Добавляем внутреннюю тень
`;

const InspirationBoostEffect = styled.img`
    border-radius: 50%;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #050F11;
    z-index: 4;
    mix-blend-mode: screen;

    //filter: opacity(0.8);
    //mix-blend-mode: color-dodge;

    //filter: opacity(0.5) brightness(2.2);

    //object-fit: cover;

    // Добавляем внутреннюю тень
`;


const float = keyframes`
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
`;


const FloatingPoints = styled.div`
    font-size: 27px;
    font-weight: bold;
    color: ${colors.activeText};
    opacity: 0;
    position: absolute;
    animation: ${float} 1s ease-out;
    z-index: 6;
`;

export default InteractiveArea;