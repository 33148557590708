import {useEffect, useRef, useState} from "react";
import { useUiStore } from "../store/userStore"; // Хранилище с состоянием пользователя

const useAccurateEnergyRestoration = (restoreRate: number) => {
    const lastUpdateTimeRef = useRef(Date.now());
    // const { energy, maxEnergy, setEnergy } = userStore(); // Получаем состояние энергии из вашего хранилища

    const energy = useUiStore((state) => state.energy);
    const maxEnergy = useUiStore((state) => state.maxEnergy);
    const setEnergy = useUiStore((state) => state.setEnergy);


    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = Date.now();
            const timeDiff = (now - lastUpdateTimeRef.current) / 1000; // разница в секундах

            // console.log(`Сейчас: ${now}, последнее обновление: ${lastUpdateTimeRef.current}, разница времени: ${timeDiff.toFixed(4)} секунд`);

            // Сколько энергии восстанавливается с момента последнего обновления
            const energyToRestore = timeDiff * restoreRate;
            const newEnergy = Math.min(energy + energyToRestore, maxEnergy); // Не больше, чем maxEnergy

            // console.log(`Восстановленная энергия за ${timeDiff.toFixed(4)} секунд: ${energyToRestore.toFixed(4)}. Текущая энергия: ${newEnergy.toFixed(4)}`);

            if (newEnergy !== energy) {
                setEnergy(newEnergy); // Обновляем энергию в состоянии
                // console.log(`Энергия обновлена: ${newEnergy.toFixed(4)} из ${maxEnergy}`);
            } else {
                // console.log('Энергия не изменилась, поскольку она уже на максимуме.');
            }

            lastUpdateTimeRef.current = now; // Обновляем время последнего обновления
        }, 1000); // Таймер срабатывает каждые 1 секунду

        return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
    }, [energy, restoreRate, maxEnergy, setEnergy]);
};

export default useAccurateEnergyRestoration;
