import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../styles/styles';

const bounce = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-2vh);
  }
`;

const Container = styled.div`
  bottom: -9vh;
  left: 50%;
  height: 7vh;
  width: 100%;
  transform: translateX(-50%);
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Ball = styled.div<{ size: number; delay: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background-color: ${colors.lightGreen};
  animation: ${bounce} 1s infinite alternate;
  animation-delay: ${props => props.delay}s;
`;

const AnimatedBalls: React.FC = () => {
    return (
        <Container>
            <Ball size={10} delay={0} />
            <Ball size={15} delay={0.2} />
            <Ball size={20} delay={0.4} />
        </Container>
    );
};

export default AnimatedBalls;

