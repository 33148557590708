import React from 'react';
import styled from "styled-components";
import {colors, CtaButton} from "../styles/styles"; // Путь к вашему компоненту
import coinIcon from "../assets/images/coin-icon.svg";
import greenCoinIcon from "../assets/images/coin-icon-green.svg";
import rebirthIcon from "../assets/images/rebirth-boost.svg";
import minusIcon from "../assets/icons/minus-icon.svg";
import achievedIcon from "../assets/icons/done-icon.svg";
import ClosePopupIcon from "../assets/images/close-popup-icon.svg"
import TimerIcon from "../assets/images/timer.svg"
import {motion} from "framer-motion";
import {LeaderboardUser} from "../store/leaderbordStore";

interface PopupProps {
    onClose: () => void;
    currentUser: LeaderboardUser;  // Информация о текущем пользователе
    totalCount: number;  // Общее количество участников
}

const Popup: React.FC<PopupProps> = ({ onClose, currentUser, totalCount }) => {

    // Логика определения, входит ли пользователь в конкретные позиции
    const calculatePositionThreshold = (totalCount: number, percentage: number) => {
        const threshold = Math.ceil((percentage / 100) * totalCount);
        return threshold === 0 ? 1 : threshold; // Минимум один человек в диапазоне
    };

    const isAchieved = (rankRange: string): boolean => {
        const { position } = currentUser;

        switch (rankRange) {
            case "top1":
                return position === 1;
            case "top1-3":
                return position >= 1 && position <= Math.min(3, totalCount); // Не больше чем участников
            case "top1-5":
                return position >= 1 && position <= Math.min(5, totalCount);
            case "top1-10":
                return position >= 1 && position <= Math.min(10, totalCount);
            case "top10%":
                return position <= calculatePositionThreshold(totalCount, 10);
            case "top20%":
                return position <= calculatePositionThreshold(totalCount, 20);
            case "top50%":
                return position <= calculatePositionThreshold(totalCount, 50);
            default:
                return false;
        }
    };


    return (
        <PopupWrapper
            className="reward-popup"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.2,
                ease: "easeInOut"
            }}
        >
            <PopupHeader>Rewards!</PopupHeader>
            <PopupSubTitle>Receive more rewards the higher you are in the ranking.</PopupSubTitle>
            <RewardsList>
                <RewardsHr>
                    <Column>Place</Column>
                    <Column>Reward</Column>
                    <Column>Your Status</Column>
                </RewardsHr>
                <RewardsContainer>
                    <RewardsRow isAchieved={isAchieved("top1")}>
                        <Column>Top 1</Column>
                        <Column><Icon src={coinIcon}/> 5%</Column>
                        <Column><Icon src={isAchieved("top1") ? achievedIcon : minusIcon}/></Column>
                    </RewardsRow>
                    <RewardsRow isAchieved={isAchieved("top1-3")}>
                        <Column>Top 1-3</Column>
                        <Column><Icon src={coinIcon}/> 4%</Column>
                        <Column><Icon src={isAchieved("top1-3") ? achievedIcon : minusIcon}/></Column>
                    </RewardsRow>
                    <RewardsRow isAchieved={isAchieved("top1-5")}>
                        <Column>Top 1-5</Column>
                        <Column><Icon src={greenCoinIcon}/> 3%</Column>
                        <Column><Icon src={isAchieved("top1-5") ? achievedIcon : minusIcon}/></Column>
                    </RewardsRow>
                    <RewardsRow isAchieved={isAchieved("top1-10")}>
                        <Column>Top 1-10</Column>
                        <Column><Icon src={greenCoinIcon}/> 2%</Column>
                        <Column><Icon src={isAchieved("top1-10") ? achievedIcon : minusIcon}/></Column>
                    </RewardsRow>
                    <RewardsRow isAchieved={isAchieved("top10%")}>
                        <Column>Top 10%</Column>
                        <Column><Icon src={greenCoinIcon}/> 1%</Column>
                        <Column><Icon src={isAchieved("top10%") ? achievedIcon : minusIcon}/></Column>
                    </RewardsRow>
                    <RewardsRow isAchieved={isAchieved("top20%")}>
                        <Column>Top 20%</Column>
                        <Column><Icon src={greenCoinIcon}/> 10000</Column>
                        <Column><Icon src={isAchieved("top20%") ? achievedIcon : minusIcon}/></Column>
                    </RewardsRow>
                    <RewardsRow isAchieved={isAchieved("top50%")}>
                        <Column>Top 50%</Column>
                        <Column><Icon src={rebirthIcon}/> Rebirth boost</Column>
                        <Column><Icon src={isAchieved("top50%") ? achievedIcon : minusIcon}/></Column>
                    </RewardsRow>
                </RewardsContainer>
            </RewardsList>
            <ClosePopupButton src={ClosePopupIcon} onClick={onClose}></ClosePopupButton>
            <PopupSubTitle>You will receive your rewards depending on your rank in:</PopupSubTitle>
            <PopupSubTitle><Icon src={TimerIcon}/> 1 day, 7 hours</PopupSubTitle>
        </PopupWrapper>
    );
};
const PopupWrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 20px);
    border: 1px solid #69FF93;
    align-items: center;
    position: fixed;
    bottom: 8.205vh;
    left: 50%;
    height: 85%;
    transform: translate(-50%, 0);
    background: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000; /* Убедитесь, что окно всплывает поверх других элементов */

    background: radial-gradient(163.59% 90.02% at 50% 0%, rgba(68, 94, 76, 0.8) 0%, rgba(9, 9, 17, 0.8) 77.42%);
    box-shadow: inset 0px 0px 130px #090911;
    backdrop-filter: blur(4px);

`;

const PopupHeader = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
    color: #FFFFFF;
    z-index: 10001;
    opacity: 1;
    filter: opacity(1);
    margin-bottom: 15px;


`;
const PopupSubTitle = styled.h2`
    font-style: normal;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #A7A7A7;
    text-transform: none;
    opacity: 1;
    filter: opacity(1);
    margin-bottom: 50px;
`;

const RewardValueTitle = styled.h2`
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: ${colors.activeText};
    margin-bottom: 50px;
`;
const ClosePopupButton = styled.img`
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;

`;

const RewardsList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const RewardsHr = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #030809;
    border: 1px solid #25362E;
    border-radius: 8px 8px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    text-transform: uppercase;
    color: #A7A7A7;


`;
const Column = styled.div`
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
`;
const Icon = styled.img`
    margin-right: 1vh;
`;
const RewardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #030809;
    border: 1px solid #25362E;
    border-radius: 0 0 8px 8px;
    overflow-y: auto;
`;

const RewardsRow = styled.div<{ isAchieved: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: ${({isAchieved}) => (isAchieved ? 'rgba(105, 255, 147, 0.15)' : '#202526')};
    margin-bottom: 1px;
    position: relative;
    color: #ffffff;
`;


export default Popup;
