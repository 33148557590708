import React, {useEffect, useRef, useState} from 'react';
import Select from 'react-select'; // Добавьте StylesConfig, если вам нужно стилизовать селект
import {useCountryStore} from '../store/countryStore';
import {styled} from "styled-components";
import greenLogoIcon from "../assets/images/green-logo.svg";
import {Link, useNavigate} from "react-router-dom";
import {colors} from "../styles/styles";
import {changeRegion} from "../api/api";

const Eden: React.FC = () => {
    const navigate = useNavigate();
    const selectRef = useRef<any>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const { country, countries, fetchCountries, setCountry } = useCountryStore();



    const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const handleMobileFocus = (e: React.FocusEvent) => {
        if (isMobile()) {

        }
    };

    useEffect(() => {
        fetchCountries(); // Вызываем фетч данных при монтировании компонента
    }, [fetchCountries]);


    const handleAcceptCountry = () => {
        if(country){
            changeRegion(country.value)
        }
        navigate('/')
    }

    return (
        <Container>
            <Title>Welcome to</Title>
            <LogoTitle>
                <img src={greenLogoIcon} alt=""/>
                2EDEN
            </LogoTitle>

            <TermsTitle>
                Acceptance of MA Terms
            </TermsTitle>
            <Subtitle>
                By using the MAF, you agree that you have read in full, understood and accepted to be legally bound by
                the terms contained herein, in addition to <StyledLink to={'https://telegram.org/tos'}>Telegram’s Terms
                of Service,</StyledLink> <StyledLink to={'https://telegram.org/privacy'}>Telegram’s Privacy
                Policy</StyledLink> and
                the respective Terms of Service of each MA you access.
            </Subtitle>
            <CountrySelectWrapper>
                <Select
                    ref={selectRef}
                    menuPlacement="top"
                    onFocus={handleMobileFocus} // Добавляем фокус обработчик
                    options={countries}
                    value={country}
                    onChange={(selectedOption: any) => setCountry(selectedOption)}
                    placeholder="Select your country"
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                            ...theme.colors,
                            primary25: '#ddd',
                            primary: '#fff',
                            neutral0: '#000',
                            neutral80: '#fff',
                        },
                    })}
                />
            </CountrySelectWrapper>
            <Button onClick={() => handleAcceptCountry()}>ENTER 2EDEN</Button>
        </Container>
    );
};

// Стилизация Select
const StyledSelect = styled(Select)`
    box-sizing: border-box;
    border: 1px solid #25362E;
    border-radius: 8px;
`;

export const LogoTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 5.024vh;
    text-align: center;
    letter-spacing: 0.3em;
    color: #69FF93;
    margin-bottom: 9.317vh;

    && img {
        margin-bottom: 2vh;
    }
`;

const TermsTitle = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 1.988vh;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 1vh;
`;

const StyledLink = styled(Link)`
    color: ${colors.lightGreen};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: ${colors.background};
    color: #fff;
    padding: 2.484vh;
    box-sizing: border-box;
    text-align: center;
`;

export const Title = styled.h1`
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.739vh;
    line-height: 1.863vh;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 5vh;
`;

export const Subtitle = styled.p`
    margin-bottom: 40px;
    font-family: 'K2D';
    font-style: normal;
    font-weight: 400;
    font-size: 1.739vh;
    color: #FFFFFF;
`;

export const CountrySelectWrapper = styled.div`
    width: 100%;
    max-width: 49.689vh;
    margin-bottom: 2.484vh;
`;

export const Button = styled.button`
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 49.689vh;
    font-size: 1.988vh;

    &:hover {
        background-color: #ddd;
    }
`;

export default Eden;
