import React from 'react';
import tasksBg from "../assets/images/tasks-bg.png";
import Lieaderboard from "../components/Lieaderboard";
import Layout from "./Layout";

const TasksPage = () => {

    return (
        <>
            <Layout background={tasksBg}>
                <Lieaderboard/>
            </Layout>
        </>

    );
}




export default TasksPage;