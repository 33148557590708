import React, {useEffect, useState} from 'react';
import {colors} from '../styles/styles';
import {useUiStore} from "../store/userStore";
import styled, {css, keyframes} from "styled-components";
import {useBoostStore} from "../store/boostStore";
import useAccurateEnergyRestoration from "../hooks/useEnergyRestoration";


const CircularScale: React.FC = () => {
    const energy = useUiStore((state) => state.energy);
    const maxEnergy = useUiStore((state) => state.maxEnergy);

    // useEffect(() => {
    //     console.log('CircularScale rendered')
    // });

    useAccurateEnergyRestoration(0.277777);

    const svgParams = {
        segments: 16,
        strokeWidth: 5,
        radius: 128,
        gap: 2,
    };

    const svgRadius = svgParams.radius - svgParams.strokeWidth;
    const circumference = 2 * Math.PI * svgRadius;
    const segmentLength = (circumference - (svgParams.segments * svgParams.gap)) / svgParams.segments;
    const visibleSegments = Math.round((energy / maxEnergy) * svgParams.segments);

    const svgSize = svgRadius * 2 + svgParams.strokeWidth * 2 + 1;
    const svgBarSize = svgParams.radius + 1;


    const isRebirthBoostActive = useBoostStore(state => state.boosts['rebirth'].isActive)
    const deactivateBoost = useBoostStore(state => state.deactivateBoost)

    const [isBoostActive, setIsBoostActive] = useState(isRebirthBoostActive);


    useEffect(() => {
        setIsBoostActive(isRebirthBoostActive)
    }, [isRebirthBoostActive]);


    // Массив сегментов для SVG
    const circles = Array.from({length: svgParams.segments}, (_, i) => {
        const offset = (i * (segmentLength + svgParams.gap)); // Смещение для текущего сегмента
        const isVisible = i < visibleSegments;


        return (
            <StyledCircle
                key={i}
                cx="50%"
                cy="50%"
                r={svgRadius}
                offset={offset}
                isBoostActive={isBoostActive}
                isVisible={isVisible}
                transform={`rotate(-90 ${svgBarSize - 0.5} ${svgBarSize - 0.5})`}
                strokeWidth={svgParams.strokeWidth}
                strokeDasharray={`${segmentLength} ${circumference}`}
                strokeDashoffset={-offset}
            />
        );

    });


    return (
        <CircularScaleWrapper isBoostActive={isBoostActive}>
            <svg style={{transform: 'scaleX(-1)'}} width='36vh' height='36vh'
                 viewBox={`0 0 ${svgSize} ${svgSize}`}>
                <circle
                    cx="50%"
                    cy="50%"
                    r={svgRadius + svgParams.strokeWidth} // Размер внешнего круга для рамки
                    fill="none"
                    stroke={colors.green} // Цвет рамки
                    strokeWidth="1" // Толщина рамки
                />
                <circle
                    cx="50%"
                    cy="50%"
                    r={svgRadius - svgParams.strokeWidth} // Размер внутреннего круга для рамки
                    fill="none"
                    stroke={colors.green} // Цвет рамки
                    strokeWidth="1" // Толщина рамки
                />
                {circles}
            </svg>

        </CircularScaleWrapper>

    );

};
const BgImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    overflow: visible;
`

const glow = keyframes`
    0%, 100% {
        box-shadow: 0 0 20px ${colors.lightGreen};
    }
    50% {
        box-shadow: 0 0 40px ${colors.lightGreen};
    }
`;

const shine = keyframes`
    0%, 100% {
        filter: brightness(1);
    }
    50% {
        filter: brightness(2.2);
    }
`;

const CircularScaleWrapper = styled.div<{ isBoostActive: boolean }>`
    display: inline-block;
    position: relative;
    border-radius: 50%;
    //height: 26vh;
    ${({isBoostActive}) =>
            isBoostActive &&
            css`
                animation: ${glow} 1s infinite;
            `}
`;

const StyledCircle = styled.circle<{ isVisible: boolean; isBoostActive: boolean }>`
    fill: none;
    stroke: ${({isVisible}) => (isVisible ? colors.green : 'transparent')};


    ${({isBoostActive}) =>
            isBoostActive &&
            css`
                animation: ${shine} 1s infinite;
            `}
`;

export default CircularScale;
