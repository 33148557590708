import React, {useState} from 'react';
import styled from 'styled-components';
import {useFrensStore} from '../store/frensStore';
import {colors, CtaButton, GradientButton} from "../styles/styles";
import telegramLine from "../assets/images/telegram-line.svg";
import copyLink from "../assets/images/copy-link-icon.svg";
import {useUiStore} from "../store/userStore";
import CoinIconBlack from "../assets/images/coin-icon-black.svg";
import ButtonFigure from "../assets/images/buttonFigure.svg";
import {toast} from "react-toastify";
import {useRewardStore} from "../store/rewardStore";
import FaqButton from "./FAQButton";
import {motion} from "framer-motion";

const Frens: React.FC = () => {

    const {userName: username, balance, currentRank, referralCode, ranks, invitedFriends} = useUiStore((state) => ({
        userName: state.userName,
        balance: state.balance,
        currentRank: state.currentRank,
        referralCode: state.referralCode,
        ranks: state.ranks,
        invitedFriends: state.invitedFriends,
    }));
    const {frensList} = useFrensStore((state) => ({
        frensList: state.frensList,
    }));
    const setRewardsToClaim = useRewardStore(state => state.setRewardsToClaim)

    const [isInfoBlockVisible, setIsInfoBlockVisible] = useState<boolean>(frensList.length > 0)


    const miningReferralReward = useRewardStore((state) => state.rewards).filter(reward => reward.type === 'mining')

    const rank = ranks[currentRank];

    // Функция для копирования ссылки
    const handleCopyLink = () => {
        const link = `https://t.me/TwoEdenBot?start=${referralCode}`; // Здесь ваша ссылка, которую нужно копировать
        navigator.clipboard.writeText(link)
            .then(() => {
                toast.success('Link copied to clipboard!');
            })
            .catch(err => {
                toast.error('Failed to copy the link');
                console.error('Error copying the link: ', err);
            });
    };
    // Функция для копирования ссылки
    const shareReferralLink = () => {
        const text = 'Играй вместе со мной'
        const url = `https://t.me/TwoEdenBot?start=${referralCode}`; // Здесь ваша ссылка, которую нужно копировать
        const link = `https://t.me/share/url?url=${url}&text=${text}`; // Здесь ваша ссылка, которую нужно копировать
        window.open(link, '_blank');
    };

    const handleClaimReward = () => {
        if (miningReferralReward.length > 0) {
            setRewardsToClaim(miningReferralReward)
        }
    }


    return (
        <FrensWrapper>
            <Title>Invite frens, <p style={{color: `${colors.lightGreen}`, marginLeft: '10px'}}>Earn points</p></Title>
            {
                isInfoBlockVisible ? (
                    <>
                        <FrensList>
                            <FrensHr>
                                <Column>Frens</Column>
                                <Column>Rank</Column>
                                <Column>balance</Column>
                                <Column>invited frens</Column>
                            </FrensHr>
                            <FrensContainer>
                                <FrensRow isCurrentUser={true}>
                                    <Column><UserName>{username}</UserName></Column>
                                    <Column><RankIcon src={rank.image}></RankIcon></Column>
                                    <Column>{balance}</Column>
                                    <Column>{invitedFriends}</Column>
                                </FrensRow>
                                {frensList.map((user, index) => (
                                    <FrensRow key={user.id} isCurrentUser={false}
                                              initial={{opacity: 0}}           // Начальное состояние: ширина 0%
                                              animate={{opacity: 1}}
                                              transition={{
                                                  duration: 0.2,                   // Длительность анимации в секундах
                                                  ease: "easeInOut"               // Эффект плавного перехода
                                              }}>
                                        <Column><UserName>{user.username}</UserName></Column>
                                        <Column><RankIcon src={ranks[user.rank.league].image}></RankIcon></Column>
                                        <Column>{user.stats.coins}</Column>
                                        <Column>{user.stats.invited_friends}</Column>
                                    </FrensRow>
                                ))}
                            </FrensContainer>
                        </FrensList>

                        <ButtonContent onClick={handleClaimReward}>
                            <Wrapper>
                                <Icon src={CoinIconBlack} alt="Coin Icon"/>
                                <ButtonPrice>{miningReferralReward[0] ? miningReferralReward[0].coins : '0'}</ButtonPrice>
                            </Wrapper>
                            <CentralIcon src={ButtonFigure} alt=""/>
                            <Wrapper>
                                <ButtonTitle>
                                    Claim
                                </ButtonTitle>
                            </Wrapper>
                        </ButtonContent>
                        <ButtonsWrapper>
                            <FrensButton onClick={shareReferralLink}><ButtonIcon src={telegramLine}
                                                                                 alt=""/> Send</FrensButton>
                            <FrensButton onClick={handleCopyLink}><ButtonIcon src={copyLink} alt=""/> Copy
                                Link</FrensButton>
                        </ButtonsWrapper>
                    </>
                ) : (
                    <Block>
                        <InfoBlock>
                            <p>For each new player invited via the link,
                                the user receives:
                            </p>
                            <br/>

                            <Highlight>5%</Highlight> of the tokens that he will farm using mining
                            in the future, which can be collected at the user’s request,<br/>
                            <Highlight>+1%</Highlight> from his invitees.
                        </InfoBlock>

                        <ButtonsWrapper>
                            <FrensButton onClick={shareReferralLink}><ButtonIcon src={telegramLine}
                                                                                 alt=""/> Send</FrensButton>
                            <FrensButton onClick={handleCopyLink}><ButtonIcon src={copyLink} alt=""/> Copy
                                Link</FrensButton>
                        </ButtonsWrapper>

                    </Block>
                )
            }

            <FaqButton onClick={() => setIsInfoBlockVisible(prevState => !prevState)}/>
        </FrensWrapper>
    );
};

const FrensWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
`;

const Title = styled.h1`
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 2.484vh;

    font-style: normal;
    font-weight: 700;
    font-size: 2.484vh;
    line-height: 3.23vh;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 1.242vh;
    margin: 1vh 0;

`
const FrensButton = styled(CtaButton)`
    flex: 1;

`
const ButtonIcon = styled.img`
    margin-right: 5px;
`


const InfoBlock = styled.div`

    font-style: normal;
    font-weight: 400;
    font-size: 1.739vh;
    color: #A7A7A7;



`;

const Highlight = styled.strong`
    font-style: normal;
    font-weight: 700;
    font-size: 1.739vh;
    line-height: 160%;

    color: #69FF93;
`
const Block = styled.div`
    width: 100%;
    background: #030809;
    border: 1px solid #25362E;
    border-radius: 8px;
    padding: 0.994vh 1.242vh 1.491vh 1.491vh;
    margin-bottom: 1vh;

`;


const FrensList = styled.div`
    display: flex;
    flex-direction: column;
`;


const FrensContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #030809;
    border: 1px solid #25362E;
    border-radius: 0 0 0.994vh 0.994vh;
    overflow-y: auto;
    height: 30vh
`;


const FrensHr = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1.242vh;
    background: #030809;
    border: 1px solid #25362E;
    border-radius: 8px 8px 0 0;
    color: ${colors.secondaryText};
    text-transform: uppercase;

`;


const Column = styled.div`
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    color: ${colors.primaryText};
    font-style: normal;
    font-weight: 400;
    font-size: 1.242vh;

`;

const RankIcon = styled.img`
    width: 20px;
    height: 29px;
`;


const FrensRow = styled(motion.div)<{ isCurrentUser: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 1.242vh;
    background-color: ${({isCurrentUser}) => (isCurrentUser ? 'rgba(105, 255, 147, 0.15)' : 'transparent')};
    margin-bottom: 5px;
    position: relative;

`;

const UserName = styled.div`

`;

const RewardIcon = styled.img`
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
`;


const ButtonContent = styled(CtaButton)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 8px;
    margin: 1vh 0;
`;

const Wrapper = styled.div`
    display: flex;
    font-size: 1.739vh;
    width: 50%;
`;

const ButtonPrice = styled.div`
`;
const ButtonTitle = styled.div`
    display: flex;
    font-size: 1.739vh;
    height: 100%;
    margin-left: auto;
    align-items: center;
    width: 50%;
    justify-content: end;
`;
const Icon = styled.img`
    margin-right: 10px;
`;
const CentralIcon = styled.img`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @media (max-height: 750px) {
        height: 4vh;
    }
`;


export default Frens;
