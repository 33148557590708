import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {colors, GradientButton} from "../styles/styles";
import MiningButton from "./MiningButton";
import BoostPopup from "./BoostPopup";
import {useBoostStore} from "../store/boostStore";

import InspirationBoostIcon from "../assets/images/inspiration-boost-icon.svg"
import RebirthBoostIcon from "../assets/images/rebirth-boost.svg"
import AIQuestionBoostIcon from "../assets/images/AI-question-icon.svg"
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

const Improvements = () => {
    const [selectedBoost, setSelectedBoost] = useState<string | null>(null);

    // Получаем функции из нашего Zustand Store

    const setBoostVisualEffect = useBoostStore((state) => state.activateBoostVisualEffect);
    const activateBoost = useBoostStore((state) => state.activateBoost);
    const boosts = useBoostStore((state) => state.boosts);


    const error = useBoostStore((state) => state.error);
    const clearError = useBoostStore((state) => state.clearError);


    useEffect(() => {
        if (error) {
            toast.error(`${error}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            clearError();
        }
    }, [error, clearError]);


    const handleBoostClick = (boostKey: string) => {
        setSelectedBoost(boostKey);
    };

    const closePopup = () => {
        setSelectedBoost(null);
        console.log('from closePopup', selectedBoost)
    };

    const handleActivateBoost = (boostKey: string) => {
        const boost = boosts[boostKey];


        if (boost.availableCount <= 0) {
            toast.error(`${boost.name}  закончились`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return
        }

        if (boostKey === 'rebirth') {
            activateBoost(boostKey)
        }
        if (boostKey === 'inspiration') {
            // ничего не делаем пока, только вешаем визуальный эффект (код снизу)

        }

        setBoostVisualEffect(boostKey)
        closePopup();
    };
    return (
        <ImprovementsWrapper>
            <MiningButton/>
            <BoostersWrapper>
                <ImprovementButton onClick={() => handleBoostClick('inspiration')}>
                    <Icon src={InspirationBoostIcon}/>
                    Inspiration boost
                </ImprovementButton>
                <ImprovementButton onClick={() => handleBoostClick('rebirth')}>
                    <Icon src={RebirthBoostIcon}/>

                    Rebirth boost
                </ImprovementButton>
            </BoostersWrapper>
            <Link to={'/aiGod'}>
                <ImprovementButton>
                    <Icon src={AIQuestionBoostIcon}/>
                    question to AI God
                </ImprovementButton>
            </Link>

            {selectedBoost && (
                <BoostPopup
                    boostType={selectedBoost}
                    onClose={closePopup}
                    onActivate={() => handleActivateBoost(selectedBoost)}
                />
            )}
        </ImprovementsWrapper>
    );
};
const ImprovementButton = styled(GradientButton)`
margin-top: 1.2vh;
`

const Icon = styled.img`
  margin-right: 5px;
`

const ImprovementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const BoostersWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default Improvements;
