import React, {useEffect} from 'react';
import CircularScale from "./EnergyBar";
import styled from "styled-components";
import InteractiveArea from "./InteractiveArea";
import {motion} from "framer-motion";


const MainButton: React.FC = () => {

    useEffect(() => {
    });


    return (
        <ButtonContainer initial={{scale: 0}}
                         animate={{scale: 1}}
                         exit={{scale: 0}}
                         transition={{duration: 0.1}}>
            <CircularScale/>
            <InteractiveArea/>
        </ButtonContainer>
    );
};

const ButtonContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    position: relative;
    z-index: 3;
    border-radius: 100%;
    width: 100%;

    && * {
        user-select: none; /* Отключает выделение текста */
        -webkit-user-select: none; /* Для Safari */
        -moz-user-select: none; /* Для Firefox */
        -ms-user-select: none; /* Для IE */
    }
`;


export default MainButton;
