import {css, styled} from 'styled-components';
import bgFigure from '../assets/images/bg-figure.png';

export const colors = {
    backgroundBlack: '#000000',
    background: '#050F11',
    primaryText: '#FFFFFF',
    white: '#FFFFFF',
    secondaryText: '#A7A7A7',
    activeText: '#69FF93',

    green: '#1A9156',
    lightGreen: '#69FF93',
    bgSecondary: '#030809'
};

export const fonts = {
    primary: '"K2D", sans-serif',
    secondary: '"Archivo", sans-serif',
};

export const shadows = {
    light: '0px 2px 8px rgba(0, 0, 0, 0.1)',
};

export const mixins = {
    boxShadow: css`
        box-shadow: ${shadows.light};
    `,
};

export const AppContainer = styled.div`
    height: 100vh;
    background-color: ${colors.background};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
`;


export const PrimaryButton = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 8px;

`;

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    position: relative;
    width: 100%;
    background-image: url(${bgFigure});
    background-position: 50% 4vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const GradientButton = styled.button`
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: ${colors.primaryText};
    text-transform: uppercase;
    min-height: 42px;
    border-radius: 8px;
    border: 1px solid ${colors.lightGreen};
    background-color: #030809;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; /* Заставляет кнопки занимать равное пространство */
    width: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -1px;
        z-index: -1;
        border-radius: 8px;
        background: linear-gradient(180deg, #0000, #00000080 30%, #000);
    }

    @media (max-height: 750px) {
        min-height: 30px;
    }

`;

export const CtaButton = styled.button`

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    height: 5.217vh;
    max-height: 42px;
    border-radius: 0.994vh;
    background-color: #fff;
    transition: background-color 0.3s, color 0.3s;
    border: none;

    &&:disabled, input:disabled {
        filter: brightness(0.5);
    }

    
    @media (max-height: 750px) {
        height: 30px;
    }
`;

