import React from 'react';
import styled, {keyframes} from 'styled-components';
import {colors, CtaButton} from "../styles/styles";
import {useBoostStore} from "../store/boostStore";
import ClosePopupIcon from "../assets/images/close-popup-icon.svg"
import {useUiStore} from "../store/userStore";

interface BoostPopupProps {
    boostType: string;
    onClose: () => void;
    onActivate: () => void;
}

const BoostPopup: React.FC<BoostPopupProps> = ({boostType, onClose, onActivate}) => {
    const boosts = useBoostStore((state) => state.boosts);
    const currentRank = useUiStore((state) => state.currentRank);
    const boost = boosts[boostType];
    console.log(boostType)

    return (
        <PopupWrapper isVisible={boostType}>
            <DescriptionWrapper>
                <BoostAnimatedIcon src={boost.image}/>
                <TextSection>
                    <BoostName>{boost.name} {`(${boost.availableCount})`}</BoostName>
                    <ClosePopup src={ClosePopupIcon}  onClick={onClose}/>
                    <Description>{boost.description}</Description>
                    <EffectUnitContainer>
                        <EffectUnit><SubTitle>Effect:</SubTitle> {boost.effect}</EffectUnit>
                        <EffectUnit><SubTitle>Activation:</SubTitle> {boost.activation}</EffectUnit>
                        <EffectUnit><SubTitle>Duration:</SubTitle> {boost.duration}</EffectUnit>
                    </EffectUnitContainer>
                </TextSection>
            </DescriptionWrapper>
            <ActivateBoostButton disabled={currentRank < 2 || boost.availableCount === 0} onClick={onActivate}>
                {currentRank < 2 ? 'Available at Rank Priest' : `Activate ${boostType}` }

            </ActivateBoostButton>
        </PopupWrapper>
    );
};


const ActivateBoostButton = styled(CtaButton)`
  width: 100%;
  min-height: 46px;
  margin-top: auto;

`;
const PopupWrapper = styled.div<{ isVisible: string }>`
  position: fixed;
  bottom: calc(62px + 1vh);
  left: 10px;
  right: 0;
  height: 250px;
  width: calc(100% - 20px);
  border: 1px solid rgba(105, 255, 147, 0.5);
  border-radius: 8px;
  background-color: ${colors.background};
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 10;
  gap: 10px;
  animation: ${props => props.isVisible === null ? slideOut : slideIn} 0.3s ease-out;
`;

const ClosePopup = styled.img`
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
`;

const BoostName = styled.h2`
  /* Inspiration boost */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  color: #69FF93;
  text-transform: uppercase;
`;
const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
  color: #A7A7A7;
`;
const EffectUnitContainer = styled.div`
    width: 100%;
  display: flex;
`;
const EffectUnit = styled.p`
  font-style: normal;
  width: 33%;
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
  color: #69FF93;
`;

const Description = styled.p`
  /* Instantly restores your energy to the maximum level. */

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #FFFFFF;


`;
const BoostAnimatedIcon = styled.img`
  max-width: 100px;
  height: 120px;
  background: #060F12;
  border: 1px solid rgba(105, 255, 147, 0.5);
  border-radius: 8px;

`;
const DescriptionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  
  
`;

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export default BoostPopup;
