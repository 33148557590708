import React, {useState} from 'react';
import infoIcon from "../assets/icons/attention-icon.svg";
import styled from "styled-components";
import SmallPopup from "./SmallPopup";
import FaqButton from "./FAQButton";

interface AiControlPanelProps {
    activeBlock: 'history' | 'current',
    handleBlockClick: (state: 'history' | 'current') => void,
}

const AiControlPanel = ({activeBlock, handleBlockClick}: AiControlPanelProps) => {
    const [isInfoPopupVisible, setIsInfoPopupVisible] = useState(false);

    const closePopup = () => {
        setIsInfoPopupVisible(false);
    };

    return (
        <Menu>
            {/*<InfoBlock onClick={() => setIsInfoPopupVisible(prevState => !prevState)}> <img src={infoIcon}*/}
            {/*                                                                                alt=""/>*/}
            {/*</InfoBlock>*/}
            <HistoryLogBlock
                isActive={activeBlock === 'history'} // Проверка, активен ли блок "history"
                onClick={() => handleBlockClick('history')} // Обработка клика
            >
                History Log
            </HistoryLogBlock>

            <CurrentMessageBlock
                isActive={activeBlock === 'current'} // Проверка, активен ли блок "current"
                onClick={() => handleBlockClick('current')} // Обработка клика
            >
                Current Message
            </CurrentMessageBlock>
            {/*<MoreBlock>More</MoreBlock>*/}


            {isInfoPopupVisible && (
                <SmallPopup onClose={closePopup}>
                    <InfoPopupHeader>
                        question to AI God
                    </InfoPopupHeader>
                    <InfoPopupText>
                        <ol>
                            <li>Here you can communicate with AI God. Discuss the meaning of life and the
                                eternal. Ask
                                philosophical questions and reasoning. For interesting and correct thematic
                                questions
                                you will receive a reward.
                            </li>
                            <li>For interesting and correct thematic questions you will receive a reward.</li>
                            <li>For interesting and correct thematic questions you will receive a reward.</li>
                        </ol>
                    </InfoPopupText>
                </SmallPopup>
            )}

            <FaqButton onClick={() => setIsInfoPopupVisible(prevState => !prevState)} />

        </Menu>


    );
};

const Menu = styled.div`
    display: flex;
    box-sizing: border-box;
    height: 24px;
    border: 1px solid #25362E;
    border-radius: 8px;
    background-color: #080D12;
    color: white;
    padding: 0 0.5vh;
    z-index: 2;
`;
const Block = styled.div<{ isActive?: boolean }>`
    box-sizing: border-box; /* Учитываем размер рамки в ширине элемента */
    /* History Logs */
    color: ${({isActive}) => (isActive ? '#69FF93' : '#A7A7A7')}; /* Меняем цвет при активном состоянии */
    background-color: #080D12;
    font-style: normal;
    
    font-weight: 400;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s ease;
`;

const InfoBlock = styled(Block)`
    width: 13.4%; /* 47.5 / 355 * 100 ≈ 13.4% */
`;

// Styled Component для блока истории
const HistoryLogBlock = styled(Block)`
    width: 49.9%; /* 130 / 355 * 100 ≈ 36.6% */
    margin-right: 1px; /* Геп в 1px между блоками */
    border-right: 1px solid #25362E; /* Рамка справа по умолчанию */
`;

// Styled Component для текущего сообщения
const CurrentMessageBlock = styled(Block)`
    width: 49.9%; /* 130 / 355 * 100 ≈ 36.6% */
`;

// Styled Component для блока "еще"
const MoreBlock = styled(Block)`
    width: 13.4%; /* 47.5 / 355 * 100 ≈ 13.4% */
    border-right: none; /* Убираем правую рамку у последнего блока */
`;// Styled Component для блока "еще"
const InfoPopupHeader = styled.div`
    font-weight: 700;
    font-size: 14px;
    color: #69FF93;
    text-transform: uppercase;
    text-align: center;
`;

const InfoPopupText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #A7A7A7;
`;
export default AiControlPanel;