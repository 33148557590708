import React from 'react';
import {styled} from "styled-components";
import {ControlPanelButton} from "./ControlPanel";
import faqButton from "../assets/icons/faq-icon.svg"


interface FaqButtonProps {
    onClick: () => void;
}

const FaqButton: React.FC<FaqButtonProps> = ({onClick}) => (

    <Button onClick={onClick}>
        <img src={faqButton} alt=""/>
    </Button>
);

const Button = styled(ControlPanelButton)`
    position: fixed;
    margin: 0;
    top: 1.118vh;
    right: 94px;
`

export default FaqButton;