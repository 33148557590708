import {create} from 'zustand';

import AcolyteIcon from '../assets/icons/acolyte.svg';
import DeaconIcon from '../assets/icons/deacon.svg';
import PriestIcon from '../assets/icons/priest.svg';
import BishopIcon from '../assets/icons/bishop.svg';
import ArchbishopIcon from '../assets/icons/archbishop.svg';
import {StoreState, UserRank} from "../types/uiStoreTypes";
import {useBoostStore} from "./boostStore";
import {useMiningStore} from "./miningStore";
import {useRankDisplayStore} from "./rankDisplayStore";
import {formatBase64String} from "../components/helpers/formatBase64String";
import {useCountryStore} from "./countryStore";
import {useFrensStore} from "./frensStore";
import { R } from '@tanstack/react-query-devtools/build/legacy/ReactQueryDevtools-Cn7cKi7o';


export const ranks: UserRank[] = [
    {
        id: 1,
        name: 'Acolyte',
        levels: [
            {id: 1, impactForce: 4, maxEnergy: 2000, recovery: 0.2777777778, price: 2640},
        ],
        image: AcolyteIcon
    },
    {
        id: 2,
        name: 'Deacon',
        levels: [
            {id: 2, impactForce: 6, maxEnergy: 3000, recovery: 0.4166666667, price: 7920}
        ],
        image: DeaconIcon
    },
    {
        id: 3,
        name: 'Priest',
        levels: [
            {id: 3, impactForce: 8, maxEnergy: 4000, recovery: 0.5555555556, price: 24200},
        ],
        image: PriestIcon
    },
    {
        id: 4,
        name: 'Archdeacon',
        levels: [
            {id: 4, impactForce: 10, maxEnergy: 5000, recovery: 0.6944444444, price: 60500},
            {id: 5, impactForce: 11, maxEnergy: 5500, recovery: 0.7638888889, price: 145200},
        ],
        image: ArchbishopIcon
    },
    {
        id: 5,
        name: 'Bishop',
        levels: [
            {id: 6, impactForce: 12, maxEnergy: 6000, recovery: 0.8333333333, price: 158400},
            {id: 7, impactForce: 13, maxEnergy: 6500, recovery: 0.9027777778, price: 257400},
            {id: 8, impactForce: 14, maxEnergy: 7000, recovery: 0.9722222222, price: 300300},
        ],
        image: BishopIcon
    },
    {
        id: 6,
        name: 'Archbishop',
        levels: [
            {id: 9, impactForce: 16, maxEnergy: 8000, recovery: 1.111111111, price: 343200},
            {id: 10, impactForce: 17, maxEnergy: 8500, recovery: 1.180555556, price: 364650},
            {id: 11, impactForce: 18, maxEnergy: 9000, recovery: 1.25, price: 514800},
        ],
        image: ArchbishopIcon
    },
    {
        id: 7,
        name: 'Metropolitan',
        levels: [
            {id: 12, impactForce: 20, maxEnergy: 10000, recovery: 1.388888889, price: 572000},
            {id: 13, impactForce: 21, maxEnergy: 10500, recovery: 1.458333333, price: 600600},
            {id: 14, impactForce: 22, maxEnergy: 11000, recovery: 1.527777778, price: 786500},
        ],
        image: ArchbishopIcon
    },
    {
        id: 8,
        name: 'Cardinal',
        levels: [
            {id: 15, impactForce: 24, maxEnergy: 12000, recovery: 1.666666667, price: 1029600},
            {id: 16, impactForce: 25, maxEnergy: 12500, recovery: 1.736111111, price: 1251250},
            {id: 17, impactForce: 26, maxEnergy: 13000, recovery: 1.805555556, price: 1673100},
        ],
        image: ArchbishopIcon
    },
    {
        id: 9,
        name: 'Patriarch',
        levels: [
            {id: 18, impactForce: 28, maxEnergy: 14000, recovery: 1.944444444, price: 2002000},
            {id: 19, impactForce: 29, maxEnergy: 14500, recovery: 2.013888889, price: 2488200},
        ],
        image: ArchbishopIcon
    },
    {
        id: 10,
        name: 'Pope',
        levels: [
            {id: 20, impactForce: 30, maxEnergy: 15000, recovery: 2.083333333, price: 0}, // Assuming 0 price for the highest rank
        ],
        image: ArchbishopIcon
    },
];


export const useUiStore = create<StoreState>((set, get) => ({
    id: 0,
    balance: 0,
    userName: '',
    currentRank: 3,
    currentLevel: 1,
    ranks,
    energy: 2000, // Начальная энергия равна максимальной энергии первого уровня первого ранга
    energyPerSecond: 0.2,
    maxEnergy: 2000,
    referralCode: '',
    avatar: '',
    coinsEarnedWeek: 0,
    country: '',
    regionChanges: 0,

    isEnergyProtectionActive: false,
    swiperInstance: null,
    isInspirationBoostActive: false,
    invitedFriends: 0,


    updateUiUserData: (userData) => {
        const {id, stats, rank, activity, referral_code, username, base64_avatar, country, leads, region_changes } = userData;
        const currentRank = ranks.findIndex(element => element.id === rank.league)
        const currentLevel = ranks[currentRank].levels.findIndex(element => element.id === rank.id)  // потому что в ui level начинается с 1
        const avatarStr = formatBase64String(base64_avatar);
        const avatarUrl = avatarStr ? avatarStr : '';
        set({
            id: id,
            userName: username,
            avatar: avatarUrl,
            balance: stats.coins,
            energy: stats.energy,
            maxEnergy: rank.max_energy,
            energyPerSecond: rank.energy_per_sec,
            currentRank: currentRank,
            currentLevel: currentLevel + 1,
            referralCode: referral_code,
            coinsEarnedWeek: stats.earned_week_coins,
            country,
            invitedFriends: stats.invited_friends,
            regionChanges: region_changes

        });

        useRankDisplayStore.setState({
            currentRankToDisplay: currentRank,
            currentLevelToDisplay: currentLevel + 1
        })


        // Обновление данных бустов
        useBoostStore.setState((state) => ({

            boosts: {
                ...state.boosts,
                inspiration: {
                    ...state.boosts.inspiration,
                    availableCount: stats.inspirations, // Количество доступных бустов для вдохновения
                },
                rebirth: {
                    ...state.boosts.rebirth,
                    availableCount: stats.replenishments, // Количество доступных бустов для восстановления энергии
                },
            },
        }));

        //обновление данных майнинг секции

        useMiningStore.getState().setIsMining(activity.is_active_mining)
        if (activity.is_active_mining) {
            useMiningStore.setState(() => ({nextMiningDate: new Date(activity.next_mining)}));

            useMiningStore.getState().startMiningStore()
        }
        // useMiningStore.setState(() => ({isMining: activity.is_active_mining}));

        //обновление стран
        if(country){
            useCountryStore.getState().setCountryByValue(country);
        }
        useFrensStore.getState().setFrensList(leads)


        // console.log('ui успешно обновлен, текущий стор =>', useUiStore.getState())
        // console.log('Boost Store обновлен, текущий стор =>', useBoostStore.getState());
        // console.log('display Store обновлен, текущий стор =>', useRankDisplayStore.getState());
        // console.log('useMiningStore  обновлен, текущий стор =>', useMiningStore.getState());
        // console.log('useCountryStore  обновлен, текущий стор =>', useCountryStore.getState());
    },


    activateEnergyProtection: () => {
        set({isEnergyProtectionActive: true});
        setTimeout(() => {
            get().deactivateEnergyProtection();
        }, 15000); // Деактивация защиты энергии через 15 секунд
    },

    deactivateEnergyProtection: () => {
        set({isEnergyProtectionActive: false});
    },

    setIsInspirationBoostActive: (flag) => set({isInspirationBoostActive: flag}),

    setSwiperInstance: (swiper) => set({swiperInstance: swiper}),

    setCurrentRank: (rankIndex) => set({currentRank: rankIndex}),

    setCurrentLevel: (level) => set({currentLevel: level}),

    increaseBalance: (amount) => set((state) => ({
        balance: state.balance + amount,
    })),
    changeEnergy: (amount) => {
        if (get().isEnergyProtectionActive) {
            return; // Не изменяем энергию, если защита активна
        }
        console.log(get().energyPerSecond)
        set((state) => ({
            energy: state.energy + amount,
        }));
    },

    setEnergy: (amount) => {
        if (get().isEnergyProtectionActive) {
            return; // Не изменяем энергию, если защита активна
        }
        set((state) => ({
            energy: amount,
        }));
    },

    restoreEnergy: () => set((state) => {
        const maxEnergy = get().maxEnergy;
        return {
            energy: maxEnergy,
        };
    }),

    upgradeRank: () => set((state) => {
        const {currentRank, currentLevel, balance, ranks, swiperInstance} = state;
        console.log(currentRank)
        const rank = ranks[currentRank];
        const isLastLevel = currentLevel >= rank.levels.length;
        const isLastRank = currentRank >= ranks.length - 1;

        if (isLastRank && isLastLevel) {
            return state; // Do nothing if at the highest rank and level
        }

        const newRank = isLastLevel ? currentRank + 1 : currentRank;
        const newLevel = isLastLevel ? 1 : currentLevel + 1;
        const newBalance = balance - rank.levels[currentLevel - 1].price;

        useRankDisplayStore.getState().setCurrentRankToDisplay(newRank)
        useRankDisplayStore.getState().setCurrentLevelToDisplay(newLevel)


        // Slide to the correct rank
        if (swiperInstance) {
            swiperInstance.slideTo(newRank);
        }


        return {
            currentRank: newRank,
            currentLevel: newLevel,
            balance: newBalance,
        };
    }),

}));


