import React from 'react';
import tasksBg from "../assets/images/tasks-bg.png";
import Frens from "../components/Frens";
import Layout from "./Layout";

const FrensPage = () => {

    return (
        <>
            <Layout background={tasksBg}>
                <Frens/>
            </Layout>
        </>

    );
}


export default FrensPage;