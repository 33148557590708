import { useEffect, useRef } from 'react';
import { syncClicks } from '../api/api';
import { useBoostStore } from '../store/boostStore';

export const useClickSync = () => {
    const clicksRef = useRef(0); // Используем useRef для хранения кликов
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const isInspirationBoostActive = useBoostStore(state => state.boosts['inspiration'].isActive);

    // Функция для синхронизации кликов
    const setClicksToSync = async (totalClicks: number) => {
        try {
            console.log(`Синхронизация: ${totalClicks} кликов`);
            await syncClicks(totalClicks); // Вызов функции синхронизации
            clicksRef.current = 0; // Обнуляем ref после синхронизации
        } catch (error) {
            console.error('Ошибка синхронизации кликов:', error);
        }
    };

    const handleClick = () => {
        // Проверяем, активен ли inspiration boost
        if (isInspirationBoostActive) {
            console.log('Boost активен, клики не синхронизируются');
            return; // Если активен, клики не учитываются
        }

        clicksRef.current += 1; // Увеличиваем значение в ref без ререндера

        // Сброс таймера
        if (timerRef.current) clearTimeout(timerRef.current);

        // Установка нового таймера на 3 секунды
        timerRef.current = setTimeout(() => {
            setClicksToSync(clicksRef.current); // Передаем текущее значение из ref
        }, 3000);
    };

    // Очистка таймера и синхронизация кликов при размонтировании компонента
    useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
            if (clicksRef.current > 0) {
                setClicksToSync(clicksRef.current); // Синхронизируем клики перед размонтированием
            }
        };
    }, []);

    return handleClick;
};
