import { create } from 'zustand';

interface AiRequestStore {
  requests: number;
  maxRequests: number;
  addRequests: (amount: number) => void;
  upgradeToMax: () => void;
}

export const useAiRequestStore = create<AiRequestStore>((set) => ({
  requests: 50,
  maxRequests: 100,
  addRequests: (amount) => set((state) => ({ requests: state.requests + amount })),
  upgradeToMax: () => set((state) => ({ requests: state.maxRequests })),
})); 