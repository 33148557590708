import React from 'react';
import ControlPanel from "../components/ControlPanel";
import Footer from "../components/Footer";
import styled from "styled-components";
import animation from "../assets/images/1.gif";
import aigodBg from "../assets/images/aigod-bg.svg";
import animationLayer1 from "../assets/animations/AI-GOD-1-layer.gif";
import animationLayer2 from "../assets/animations/AI-GOD-2-layer.gif";
import AiQuestion from "../components/AiQuestion";
import {motion} from "framer-motion";
import AnimatedBalls from '../components/AnimatedBalls';

const AiGodPage = () => {

    const IsolatedComponent = styled.div`
    all: revert;
    filter: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    
    * {
      filter: none;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
    }
  `;
    return (
        <>
            <AiGodPageContainer initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}>
                <ControlPanel/>



                <ButtonContentWrapper>
                    <AiGodImageContainer>
                        <ButtonBackgroundImage
                                src={animation}
                            alt="Animation"
                        />
                        <Layer1
                            src={animationLayer1}
                            alt="Animation"
                        />
                        <Layer2
                            src={animationLayer2}
                            alt="Animation"
                        />
                        <Overlay/>
                        <AnimatedBalls />
                    </AiGodImageContainer>

                </ButtonContentWrapper>
                <AiQuestion/>

            </AiGodPageContainer>
            <Footer/>
        </>

    );
}

const AiGodImageContainer = styled.div`
    width: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
    overflow: hidden;
    border-radius: 50%;
    border: none;

    box-shadow: 0 0 25px 1px #69FF93;


    // Псевдоэлемент для внутренней тени

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0 0 15px 1px #69FF93;
        pointer-events: none; // Не мешает взаимодействию с изображением
        z-index: 4; // Псевдоэлемент должен быть над изображением

    }
`;

const ButtonBackgroundImage = styled.img`
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #050F11;
    z-index: 0;
    opacity: 1;
    filter: brightness(2);

`;
const Layer1 = styled.img`
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #050F11;
    opacity: 1;
    z-index: 2;
    filter: grayscale(1);
    mix-blend-mode: screen;

`;
const Layer2 = styled.img`
    position: absolute;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #050F11;
    opacity: 0.8;
    filter: brightness(1.3);
    z-index: 1;
    mix-blend-mode: luminosity;
`;

const ButtonContentWrapper = styled.div`
    position: absolute;
    top: -6vh;
    left: 50%;
    transform: translateX(-50%) translateY(70.5%);

`;

const Overlay = styled.div`
    position: absolute;
    top: 1px;
    left: 1px;
    width: 99%;
    height: 99%;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out, mix-blend-mode 0.6s ease-in-out;
    pointer-events: none;
    background: #69FFC0;
    mix-blend-mode: color;
    z-index: 3;
`;


const AiGodPageContainer = styled(motion.div)`
    background-image: url(${aigodBg});
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    position: relative;
    width: 100%;
    background-position: 50% -6vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
`


export default AiGodPage;