import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useLeaderboardStore} from '../store/leaderbordStore';
import {ranks} from "../store/userStore";
import {colors, GradientButton} from "../styles/styles";
import arrowBackIcon from "../assets/images/arrow-back-icon.svg";
import arrowNextIcon from "../assets/images/arrow-next-icon.svg";
import LeaderboardPopup from "./LeaderboardPopup";
import FaqButton from "./FAQButton";
import {motion} from "framer-motion";
import { useQuery } from '@tanstack/react-query';
import { getLeaderboard } from '../api/api';
import Spinner from './helpers/Spinner';


const Leaderboard: React.FC = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);

    const {data, isLoading, error} = useQuery({
        queryKey: ['leaderboard'],
        queryFn: () => getLeaderboard()
    });
    const {
        seasons,
        currentSeasonIndex,
        leaderboard,
        currentUser,
        setCurrentSeason,
        setLeaderboardData,
        totalCount
    } = useLeaderboardStore();

    useEffect(() => {
        if (data) {
            setLeaderboardData(data);
        }
    }, [data]);


    const handlePreviousSeason = () => {
        if (currentSeasonIndex > 0) {
            setCurrentSeason(currentSeasonIndex - 1);
        }
    };

    const handleNextSeason = () => {
        if (currentSeasonIndex < seasons.length - 1) {
            setCurrentSeason(currentSeasonIndex + 1);
        }
    };

    if (isLoading) {
        return <Spinner/>;  // Показать индикатор загрузки, пока данные загружаются
    }

    if (error) {
        return <div>Error: {error.message}</div>;  // Показать сообщение об ошибке

    }

    return (

        <LeaderboardWrapper>
            <LeaderboardHeader>
                <Header>
                    <NavigationButton onClick={handlePreviousSeason}><img src={arrowBackIcon}
                                                                          alt=""/></NavigationButton>
                    <Title>
                        Leaderboard
                        <SeasonInfo>
                            {seasons[currentSeasonIndex].title}
                            {seasons[currentSeasonIndex].date}
                        </SeasonInfo>
                    </Title>
                    <NavigationButton onClick={handleNextSeason}><img src={arrowNextIcon} alt=""/></NavigationButton>
                </Header>

            </LeaderboardHeader>

            <LeaderboardHr>
                <Column>Place</Column>
                <Column>Balance</Column>
                <Column>Rank</Column>
                <Column>User</Column>
            </LeaderboardHr>
            <LeaderboardContainer>
                <LeaderboardRow isAchieved={true}>
                    <Column>{currentUser?.position}</Column>
                    <Column>{currentUser?.coins}</Column>
                    <Column>
                        <RankIcon src={ranks?.find(rank => rank.name === currentUser?.rank)?.image}>
                        </RankIcon>
                    </Column>
                    <Column><UserName>{currentUser?.username}</UserName></Column>
                </LeaderboardRow>
                {leaderboard.filter((user) => user.id != currentUser?.id).map((user, index) => (
                    <LeaderboardRow key={user.id} isAchieved={false}
                                    initial={{opacity: 0}}           // Начальное состояние: ширина 0%
                                    animate={{opacity: 1}}       // Анимация: ширина 100%
                                    transition={{
                                        duration: 0.2,                   // Длительность анимации в секундах
                                        ease: "easeInOut"               // Эффект плавного перехода
                                    }}>
                        <Column>{user.position}</Column>
                        <Column>{user.coins}</Column>
                        <Column><RankIcon src={ranks?.find(rank => rank.name === currentUser?.rank)?.image}></RankIcon></Column>
                        <Column><UserName>{user.username}</UserName></Column>
                    </LeaderboardRow>
                ))}
            </LeaderboardContainer>

            {isPopupVisible && <LeaderboardPopup totalCount={totalCount} currentUser={currentUser}
                                                 onClose={() => setPopupVisible(false)}/>}

            <FaqButton onClick={() => setPopupVisible(prevState => !prevState)}/>
        </LeaderboardWrapper>
    )


};


const LeaderboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 47vh;
    margin-top: 6vh;
    flex-grow: 1;

`;

const LeaderboardHeader = styled.div`
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: ${colors.primaryText};

`;

const LeaderboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #030809;
    border: 1px solid #25362E;
    border-radius: 0 0 8px 8px;
    overflow-y: auto;
    flex-grow: 1;
`;

const Title = styled.h1`
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
`;

const SeasonInfo = styled.div`
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #A7A7A7;


`;

const NavigationButton = styled(GradientButton)`
    width: 32px;
    height: 32px;
    flex: none;
    min-height: 32px;
`;


const LeaderboardHr = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #030809;
    border: 1px solid #25362E;
    border-radius: 8px 8px 0 0;
    color: ${colors.secondaryText};

`;


const Column = styled.div`
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    color: ${colors.primaryText};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
`;

const RankIcon = styled.img`
    width: 20px;
    height: 29px;
`;


const LeaderboardRow = styled(motion.div)<{ isAchieved: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: ${({isAchieved}) => (isAchieved ? 'rgba(105, 255, 147, 0.15)' : 'transparent')};
    margin-bottom: 5px;
    position: relative;
    //overflow: hidden;

`;

const UserName = styled.div`

`;

const RewardIcon = styled.img`
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
`;


export default Leaderboard;