import React, {useEffect, useLayoutEffect} from 'react';
import styled from 'styled-components';
import {useUiStore} from '../store/userStore';
import {colors, CtaButton} from "../styles/styles";
import {useRankDisplayStore} from "../store/rankDisplayStore";
import ButtonFigure from '../assets/images/buttonFigure.svg'
import CoinIconBlack from '../assets/images/coin-icon-black.svg'
import ProgressBar from "./ProgressBar";
import {promoteRank} from "../api/api";
import {log} from "node:util";


const Stats: React.FC = () => {
    const balance = useUiStore((state) => state.balance);
    const currentRank = useUiStore((state) => state.currentRank);
    const currentLevel = useUiStore((state) => state.currentLevel);
    const upgradeRank = useUiStore((state) => state.upgradeRank);
    const ranks = useUiStore((state) => state.ranks);
    const currentRankToDisplay = useRankDisplayStore((state) => state.currentRankToDisplay);
    const currentLevelToDisplay = useRankDisplayStore((state) => state.currentLevelToDisplay);



    const rank = ranks[currentRankToDisplay];


    const isUpgradeActive = balance >= rank.levels[currentLevelToDisplay - 1].price &&
        (currentRank < ranks.length - 1 || currentLevel < ranks[currentRank].levels.length) &&
        (currentRank === currentRankToDisplay && currentLevel === currentLevelToDisplay);

    const handleClick = async () => {

        const promoteResponse = await promoteRank()
        // if(promoteResponse.message.status === "Success"){
        upgradeRank()
        console.log(promoteResponse)

        // }
    };

    const buttonTitle = () => {

        console.log('currentRank', currentRank)
        console.log('currentRankToDisplay', currentRankToDisplay)

        console.log('currentLevel', currentLevel)
        console.log('currentLevelToDisplay', currentLevelToDisplay)

        if ((currentRankToDisplay > currentRank) || (currentRankToDisplay === currentRank && currentLevel < currentLevelToDisplay)) {
            console.log(`currentLevelToDisplay ${currentLevelToDisplay} > currentRank ${currentRank} `)
            return 'FUTURE RANK'
        }
        if (currentRankToDisplay < currentRank) {
            console.log(`currentLevelToDisplay ${currentLevelToDisplay} < currentRank ${currentRank} `)
            return 'ACHIEVED'
        } else return 'UPGRADE'
    }
    return (
        <div style={{marginTop: 'auto'}}>
            <ProgressBar/>
            <StatsContainer>
                <StatItem>
                    <span>Impact force (per click):</span>
                    <span>{rank.levels[currentLevelToDisplay - 1].impactForce}</span>
                </StatItem>
                <StatItem>
                    <span>Max energy:</span>
                    <span>{rank.levels[currentLevelToDisplay - 1].maxEnergy}</span>
                </StatItem>
                <StatItem>
                    <span>Recovery (per second):</span>
                    <span>{rank.levels[currentLevelToDisplay - 1].recovery.toFixed(2)}</span>
                </StatItem>
                <UpgradeButton
                    isActive={isUpgradeActive}
                    onClick={isUpgradeActive ? handleClick : undefined}
                >
                    <ButtonPriceWrapper>
                        <img src={CoinIconBlack} alt="Coin Icon"/>
                        <ButtonPrice>{rank.levels[currentLevelToDisplay - 1].price}</ButtonPrice>
                    </ButtonPriceWrapper>

                    <Icon src={ButtonFigure} alt=""/>

                    <ButtonTitle>{buttonTitle()}</ButtonTitle>
                </UpgradeButton>
            </StatsContainer>

        </div>
    );
};


const StatsContainer = styled.div`
    width: 100%;
    padding: 10px;
    border: 1px solid #25362E;
    background-color: ${colors.bgSecondary};
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: auto;
`;

const StatItem = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: ${colors.secondaryText};
`;

const ButtonPriceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
`;
const ButtonPrice = styled.div`
    margin-left: 10px;
`;
const ButtonTitle = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
`;

const Icon = styled.img`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @media (max-height: 750px) {
        height: 4vh;
    }
`;

const UpgradeButton = styled(CtaButton)<{ isActive: boolean }>`
    position: relative;
    width: 100%;
    background-color: ${props => props.isActive ? '#fff' : '#d3d3d3'};
    color: ${props => props.isActive ? '#000' : '#666'};
    cursor: ${props => props.isActive ? 'pointer' : 'not-allowed'};
    justify-content: space-between;
    padding: 0 24px;
`;

export default Stats;
