import React, {useEffect} from 'react';
import MainButton from "../components/MainButton";
import Improvements from "../components/Improvements";
import Layout from "./Layout";
import {getRewardsList} from "../api/api";
import {Reward, useRewardStore} from "../store/rewardStore";
import { useQuery } from '@tanstack/react-query';


const HomePage = () => {
    const setRewards = useRewardStore(state => state.setRewards); // Для загрузки наград
    const setRewardsToClaim = useRewardStore(state => state.setRewardsToClaim);
     // Для загрузки наград

    // Используем React Query для запроса данных с бэка
    const { data: rewards, error, refetch } = useQuery({
        queryKey: ['rewards'], // Ключ запроса
        queryFn: () => getRewardsList(), // Функция запроса
        staleTime: 60000, // Данные будут считаться актуальными в течение 60 секунд
        refetchOnWindowFocus: false, // Не обновлять при фокусе на окне
    });



    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            const TgBackButton = window.Telegram.WebApp.BackButton;
            TgBackButton.hide();
        }
        console.log('rewards', rewards);

        if (rewards) {
            setRewards(rewards);
            const startRewards = rewards.filter((reward: Reward) => 
                reward.type === "launches_series" || 
                reward.type === "invite_friends" || 
                reward.type === "leaderboard"
            );
            if (startRewards.length > 0) {
                setRewardsToClaim(startRewards);
            }
        } else if (error) {
            console.error('Ошибка при загрузке наград:', error);
        }
    }, [rewards, error, setRewards, setRewardsToClaim]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            refetch(); // Принудительно запрашиваем данные с бэка при обновлении
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [refetch]);

    return (
        <Layout>
            <MainButton/>
            <Improvements/>
        </Layout>
    );
};


export default HomePage;