import React from 'react';
import ControlPanel from "../components/ControlPanel";
import Avatar from "../components/Avatar";
import Footer from "../components/Footer";
import styled from "styled-components";
import bgFigure from "../assets/images/bg-figure.png"; // Импортируем изображение по умолчанию

interface LayoutProps {
    children: React.ReactNode;
    background?: string; // Пропс для фона
}

const Layout: React.FC<LayoutProps> = ({ children, background = bgFigure }) => { // Устанавливаем значение по умолчанию
    return (
        <>
            <Container background={background}>
                {/* Общие элементы для всех страниц */}
                <ControlPanel />
                <Avatar />
                {children} {/* Уникальный контент каждой страницы */}
            </Container>
            <Footer />
        </>
    );
};

// Теперь background приходит через пропсы
export const Container = styled.div<{ background: string }>` // background теперь обязателен
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    position: relative;
    width: 100%;
    background-image: url(${(props) => props.background}); // Используем пропс
    background-position: 50% 4vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
`;

export default Layout;
