import React, {useEffect} from 'react';
import Header from "../components/Header";
import {Container} from "../styles/styles";
import ControlPanel from "../components/ControlPanel";
import Avatar from "../components/Avatar";
import RankList from "../components/RankList";
import Stats from "../components/Stats";
import Footer from "../components/Footer";
import Layout from "./Layout";

const RanksPage = () => {


    return (
        <>
            <Layout>
                <RankList/>
                <Stats/>
            </Layout>
        </>

    );
};

export default RanksPage;