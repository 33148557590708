import React, { useState } from 'react';
import styled from "styled-components";
import { colors } from "../styles/styles";
import { Question } from "../store/ChatStore";
import {motion} from "framer-motion";

interface IProps {
    questionsHistory: Question[];
}

const AiHistory = ({ questionsHistory }: IProps) => {
    const [openIndexes, setOpenIndexes] = useState<number[]>([]);

    const toggleAnswerVisibility = (index: number) => {
        if (openIndexes.includes(index)) {
            setOpenIndexes(openIndexes.filter((i) => i !== index));
        } else {
            setOpenIndexes([...openIndexes, index]);
        }
    };

    return (
            <HistoryList       initial={{ y: "100vh" }} // Элемент изначально ниже экрана
                               animate={{ y: 0 }} // При анимации элемент перемещается в центр
                               exit={{ y: "100vh" }} // При выходе элемент уезжает обратно вниз
                               transition={{
                                   duration: 0.1, // Длительность анимации
                               }}>
                {questionsHistory.map((message, index) => (
                    <HistoryItem key={index}>
                        <UserQuestion isSecret={message.secret} onClick={() => toggleAnswerVisibility(index)}>
                            <UserQuestionHeader>
                                <From>You:</From>
                                <UserQuestionDate>{formatDate(message.time_sent)}</UserQuestionDate>
                            </UserQuestionHeader>
                            {message.u_text}
                        </UserQuestion>

                        {/* Показываем ответ только если индекс есть в массиве открытых */}
                        {openIndexes.includes(index) && (
                            <AiAnswer>{message.answer}</AiAnswer>
                        )}
                    </HistoryItem>
                ))}
            </HistoryList>
    );
};

function formatDate(isoString: string) {
    const date = new Date(isoString);
    if (isNaN(date.getTime())) {
        return 'Invalid date';
    }

    return date.toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
}

const UserQuestionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const From = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #A7A7A7;
`;



const HistoryList = styled(motion.ul)`
    list-style: none;
    margin-top: 1px;
    padding: 10px;
    border: 1px solid #25362E;
    color: #4cd964;
    background-color: #0D1417;
    border-radius: 4px;
    overflow-y: auto;
    z-index: 2;
`;

const HistoryItem = styled.li`
    padding: 1vh 0;
    background-color: #000000;
    border-top: 1px solid #25362E;
    margin-bottom: 1px;
`;

const UserQuestionDate = styled.div`
    font-size: 1vh;
    color: ${colors.secondaryText};
`;

const UserQuestion = styled.li<{ isSecret: boolean }>`
    padding: 5px 5px;
    color: ${({ isSecret }) => (isSecret ? 'gold' : 'white')};
    font-size: 14px;
    cursor: pointer;
`;


const AiAnswer = styled.li`
    padding: 5px 5px;
    color: ${colors.secondaryText};
    color: ${colors.activeText};
`;

export default AiHistory;
