import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useBoostStore } from '../store/boostStore';
import { FaStar, FaClock } from "react-icons/fa";
import inspirationIcon from "../assets/images/inspiration-boost-icon.svg";
import rebirthIcon from "../assets/images/rebirth-boost.svg";
import { CtaButton, colors } from '../styles/styles';

const BoostPurchase = () => {
    const { boosts, addBoosts } = useBoostStore();
    const [amounts, setAmounts] = useState({ inspiration: 0, rebirth: 0 });

    const PRICES = { inspiration: 10, rebirth: 20 };
    const PACK_DISCOUNT = 20;
    const PACK_SIZE = 10;

    const calculatePrice = (amount: number, basePrice: number) => amount * basePrice;

    const handleSliderChange = (type: 'inspiration' | 'rebirth', value: string) => {
        setAmounts(prev => ({ ...prev, [type]: parseInt(value) }));
    };

    const getTotalPrice = () => {
        return Object.entries(amounts).reduce((total, [type, amount]) => {
            return total + calculatePrice(amount, PRICES[type as keyof typeof PRICES]);
        }, 0);
    };

    const handlePurchase = async (type: 'inspiration' | 'rebirth' | 'pack', amount: number, price: number) => {
        try {
            const tg = window.Telegram.WebApp;
            const title = type === 'pack' ? 'Mega Boost Pack' : `${amount}x ${type} boost`;
            const description = type === 'pack' ? `Purchase pack of ${PACK_SIZE}x Inspiration and ${PACK_SIZE}x Rebirth boosts` : `Purchase ${amount} ${type} boost(s)`;
            const invoiceParams = {
                title,
                description,
                payload: JSON.stringify({ type, amount }),
                provider_token: process.env.REACT_APP_PROVIDER_TOKEN || '',
                currency: 'USD',
                prices: [{ label: title, amount: price * 100 }],
                max_tip_amount: 0,
                suggested_tip_amounts: [],
                need_name: false,
                need_phone_number: false,
                need_email: false,
                need_shipping_address: false,
                is_flexible: false
            };

            const result = await tg.openInvoice(invoiceParams);

            if (result) {
                if (type === 'pack') {
                    addBoosts('inspiration', PACK_SIZE);
                    addBoosts('rebirth', PACK_SIZE);
                } else {
                    addBoosts(type, amount);
                }
            }
        } catch (error) {
            console.error('Purchase failed:', error);
        }
    };

    const renderBoostSection = (type: 'inspiration' | 'rebirth', icon: string) => (
        <BoostSection>
            <BoostHeader>
                <BoostIcon src={icon} />
                <BoostInfo>
                    <BoostName>{type.charAt(0).toUpperCase() + type.slice(1)} Boost</BoostName>
                    <BoostCount>You have: {boosts[type].availableCount}</BoostCount>
                </BoostInfo>
                <AmountDisplay>{amounts[type]}</AmountDisplay>
            </BoostHeader>
            <SliderContainer>
                <Slider
                    type="range"
                    min="0"
                    max="50"
                    value={amounts[type]}
                    onChange={(e) => handleSliderChange(type, e.target.value)}
                />
                <SliderMarks>
                    {[0, 10, 20, 30, 40, 50].map(mark => <Mark key={mark}>{mark}</Mark>)}
                </SliderMarks>
            </SliderContainer>
        </BoostSection>
    );

    const packPrices = calculatePrice(PACK_SIZE, PRICES.inspiration + PRICES.rebirth);

    return (
        <>
            <BoostCard>
                <BoostsContainer>
                    {renderBoostSection('inspiration', inspirationIcon)}
                    <Divider />
                    {renderBoostSection('rebirth', rebirthIcon)}
                </BoostsContainer>
                <BuyButton
                    disabled={getTotalPrice() === 0}
                    onClick={() => {
                        if (amounts.inspiration > 0) {
                            handlePurchase('inspiration', amounts.inspiration, calculatePrice(amounts.inspiration, PRICES.inspiration));
                        }
                        if (amounts.rebirth > 0) {
                            handlePurchase('rebirth', amounts.rebirth, calculatePrice(amounts.rebirth, PRICES.rebirth));
                        }
                    }}
                >
                    {getTotalPrice() === 0 ? 'Select amount' : `Buy for ${getTotalPrice()}`}
                    {getTotalPrice() > 0 && <FaStar style={{ color: 'gold', marginLeft: '1vh' }} />}
                </BuyButton>

            </BoostCard>
            <BoostCard>

            <PackSection>
              <DailyLimitBadgeContainer>
              <PackTitle>Mega Boost Pack</PackTitle>

                    <DiscountBadge>{PACK_DISCOUNT}% OFF</DiscountBadge>


              </DailyLimitBadgeContainer>
                <PackHeader>
                </PackHeader>
                <PackContent>
                    <PackItem>
                        <BoostIcon src={inspirationIcon} />
                        <PackItemText>{PACK_SIZE}x Inspiration Boost</PackItemText>
                    </PackItem>
                    <PackItem>
                        <BoostIcon src={rebirthIcon} />
                        <PackItemText>{PACK_SIZE}x Rebirth Boost</PackItemText>
                    </PackItem>
                </PackContent>
                <PackPricing>
                    <OldPrice>{packPrices} <FaStar /></OldPrice>
                    <NewPrice>{packPrices * (1 - PACK_DISCOUNT / 100)} <FaStar style={{ color: 'gold' }} /></NewPrice>
                    <DailyLimitBadge>
                        <FaClock /> Once per day
                    </DailyLimitBadge>


                </PackPricing>
                <BuyPackButton onClick={() => handlePurchase('pack', PACK_SIZE, packPrices * (1 - PACK_DISCOUNT / 100))}>
                    Buy Pack
                </BuyPackButton>
            </PackSection>
            </BoostCard>

            
        </>

    );
};

// Стили остаются теми же, что и в StorePage
const BoostCard = styled.div`
  background: ${colors.bgSecondary};
  border-radius: 1.5vh;
  padding: 2vh;
  margin-top: 2vh;    
`;

const BoostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
`;

const BoostSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vh;
`;

const Divider = styled.div`
  height: 1px;
  background: ${colors.lightGreen};
  margin: 1vh 0;
`;

const BoostHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5vh;
`;

const BoostIcon = styled.img`
  width: 5vh;
  height: 5vh;
`;

const BoostInfo = styled.div`
  flex: 1;
`;

const AmountDisplay = styled.div`
  color: ${colors.lightGreen};
  font-size: 2.5vh;
  font-weight: bold;
  min-width: 3vh;
  text-align: right;
`;

const BoostName = styled.h3`
  color: ${colors.primaryText};
  font-size: 2vh;
  margin: 0;
`;

const BoostCount = styled.p`
  color: ${colors.secondaryText};
  font-size: 1.6vh;
  margin: 0.5vh 0 0;
`;

const SliderContainer = styled.div`
  padding: 0 1vh;
`;

const SliderMarks = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.5vh;
  margin-top: 0.5vh;
`;

const Mark = styled.span`
  color: ${colors.secondaryText};
  font-size: 1.4vh;
  position: relative;
  width: 6%;
  text-align: center;
  
  &::before {
    content: '';
    position: absolute;
    top: -1vh;
    left: 50%;
    transform: translateX(-50%);
    width: 0.2vh;
    height: 0.5vh;
    background: ${colors.secondaryText};
  }
`;

const Slider = styled.input`
  width: 100%;
  height: 0.5vh;
  background: ${colors.lightGreen}40;
  border-radius: 0.25vh;
  -webkit-appearance: none;
  margin: 1vh 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 2.5vh;
    height: 2.5vh;
    background: ${colors.lightGreen};
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 1vh ${colors.lightGreen}40;
    transition: all 0.2s ease;
    position: relative;
    top: -1vh;
    z-index: 1;
  }

  &::-webkit-slider-thumb:hover {
    transform: scale(1.1);
    box-shadow: 0 0 1.5vh ${colors.lightGreen}60;
  }

  &::-webkit-slider-runnable-track {
    height: 0.5vh;
    border-radius: 0.25vh;
  }
`;

const BuyButton = styled(CtaButton)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 1vh;
`;

const PackSection = styled.div`
`;

const PackHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 1vh;
`;

const PackTitle = styled.h3`
  color: ${colors.primaryText};
  font-size: 2vh;
  margin: 0;
`;

const DiscountBadge = styled.span`
  margin-left: auto;
  background: ${colors.lightGreen};
  color: ${colors.background};
  padding: 0.5vh 1vh;
  border-radius: 1vh;
  font-size: 1.6vh;
  font-weight: bold;
`;

const PackContent = styled.div`
  margin-bottom: 1vh;
`;

const PackItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5vh;
`;

const PackItemText = styled.span`
  color: ${colors.primaryText};
  font-size: 1.6vh;
`;

const PackPricing = styled.div`
  display: flex;
  align-items: center;
  gap: 1vh;
  margin-bottom: 1vh;
`;

const OldPrice = styled.span`
  color: ${colors.secondaryText};
  text-decoration: line-through;
  font-size: 1.6vh;
  display: flex;
  align-items: center;
  gap: 0.5vh;
`;

const NewPrice = styled.span`
  color: ${colors.lightGreen};
  font-size: 2vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5vh;
`;

const BuyPackButton = styled(BuyButton)`
  color: ${colors.background};
  font-weight: bold;
`;

const DailyLimitBadge = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.5vh;
    background: ${colors.lightGreen}15;
    color: ${colors.lightGreen};
    font-size: 1.4vh;
    font-weight: 500;
    padding: 0.5vh 1vh;
    border-radius: 0.8vh;
    // margin-bottom: 1.5vh;
    margin-left: auto;
    
    svg {
        font-size: 1.2vh;
    }
`;

const DailyLimitBadgeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vh;
`;

export default BoostPurchase; 